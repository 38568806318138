import React from 'react';

import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import {
  chartOfAccountsPath,
  multiEntityReportsPath,
  portfolioDashboardsPath,
} from '@src/routes';

import Root from '@src/components/root';

import ModuleHeaderMenu from './module_header_menu';

import styles from '../styles.module.scss';

const ModulePortfolioHeaderPage = () => {
  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const isCOAEnabled = useUserFeatureFlag(featureFlag);
  const path = window.location.pathname.split('/');
  
  return (
    <Root>
      <div className={ styles['service-menu-page'] }>
        <ModuleHeaderMenu
          active={ path[1] === window.Docyt.Common.Constants.PORTFOLIO_PAGES.dashboard }
          title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.DASHBOARD }
          url={ portfolioDashboardsPath() }
        />
        <ModuleHeaderMenu
          active={ path[1] === window.Docyt.Common.Constants.PORTFOLIO_PAGES.reports }
          title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.REPORTS }
          url={ multiEntityReportsPath() }
        />
        {isCOAEnabled && (
          <ModuleHeaderMenu
            active={ path[1] === window.Docyt.Common.Constants.PORTFOLIO_PAGES.coa }
            title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.COA }
            url={ chartOfAccountsPath() }
          />
        )}
      </div>
    </Root>
  );
};

export default ModulePortfolioHeaderPage;
