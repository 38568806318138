import React, { useCallback, useMemo } from 'react';

import { LoadOptions } from 'react-select-async-paginate';

import { useGetBusinessVendor } from '@src/hooks/queries/vendors';
import { searchBusinessVendors } from '@src/requests/business_vendors';
import { TID } from '@src/types/common';

import { VendorAvatar } from '@src/components/ui/avatars';
import { AsyncPaginateIconSelectInput } from '@src/components/ui_v2/inputs';

interface IVendorInputProps
{
  businessId: TID,
  handleSelected: (items: any) => void,
  value: string,
  disabled?: boolean
}

type OptionType = {
  label: string;
  value: string;
  icon: JSX.Element;
  helper: string;
};

const PER_PAGE = 50;

const VendorInput = ({
  businessId,
  handleSelected,
  value,
  disabled = false,
}: IVendorInputProps) => {
  const vendorQuery = useGetBusinessVendor({ businessId, vendorId: Number(value) });

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!vendorQuery.data) return undefined;
    if (String(vendorQuery.data.id) !== String(value)) return undefined;

    return {
      icon:   <VendorAvatar size="100%" vendor={ vendorQuery.data } />,
      label:  vendorQuery.data.name,
      helper: vendorQuery.data.qboEntityType,
      value:  String(vendorQuery.data.id),
    };
  }, [value, vendorQuery.data]);

  const handleSource =
    useCallback((query, options, { page }) => {
      return searchBusinessVendors({ businessId, search: query, page, perPage: PER_PAGE })
        .then((data) => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          const newOptions = data.collection
            // .filter((i) => i.businessVendorId)
            .map((vendor) => ({
              label:  vendor.name,
              value:  String(vendor.vendorId),
              icon:   <VendorAvatar size="100%" vendor={ vendor } />,
              helper: vendor.individualUserId ? 'Employee' : 'Vendor',
            }));

          // eslint-disable-next-line max-len
          const categorizeAndSortOptions = (opt: OptionType[], helperType: string, label: string) => {
            const categorizedOptions = opt.filter((i) => i.helper === helperType);
            categorizedOptions.sort((a, b) => a.label.localeCompare(b.label));
            if (page === 1) {
              if (categorizedOptions.length > 0) {
                categorizedOptions.unshift({
                  label,
                  value:  '',
                  helper: '',
                  icon:   <>&nbsp;</>,
                });
              }
            }
            return categorizedOptions;
          };

          let EmployeeOptions: OptionType[] = [];
          let VendorOptions: OptionType[] = [];
          if (newOptions.length > 0) {
            EmployeeOptions = categorizeAndSortOptions(newOptions, 'Employee', 'From My Employees');
            VendorOptions = categorizeAndSortOptions(newOptions, 'Vendor', 'From My Vendors');
          }

          const allOptions = [...VendorOptions, ...EmployeeOptions];

          return {
            hasMore,
            options:    allOptions,
            additional: {
              page: page + 1,
            },
          };
        });
    }, [businessId]);

  return (
    <AsyncPaginateIconSelectInput
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      isDisabled={ disabled }
      isLoading={ vendorQuery.isLoading }
      loadOptions={ handleSource as LoadOptions<any, any, any> }
      placeholder="Add Name"
      value={ selectedItem }
      onChange={ handleSelected }
    />
  );
};

export default VendorInput;
