import React, { useCallback } from 'react';

import { useDropdown } from './hooks';

interface IDropdownToggleButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  handleDropdown?:()=>void
}

const DropdownToggleButton = ({
  children,
  className,
  onClick,
  handleDropdown,
  ...props
}: IDropdownToggleButtonProps) => {
  const { toggle } = useDropdown() || {};

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick();
      return;
    }

    if (toggle) toggle();

    if (handleDropdown) { 
      handleDropdown();
    }

  }, [onClick, toggle]);

  return (
    <button
      className={ className }
      type="button"
      onClick={ handleClick }
      { ...props }
    >
      { children }
    </button>
  );
};

export default React.memo(DropdownToggleButton);
