import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import styles from '../../styles.module.scss';

interface IQuickbooksWarningModalProps extends IUseModalProps {
  title: string;
  documentType: string;
  actionType: string;
  actionDateTitle: string;
  reconciliationClosingDateStr: string;
  paymentAccountStartDateStr: string;
  actionDateStr: string;
}

const QuickbooksWarningModal = ({
  isOpen,
  title,
  documentType,
  actionType,
  actionDateTitle,
  reconciliationClosingDateStr,
  paymentAccountStartDateStr,
  actionDateStr,
  onCancel,
}: IQuickbooksWarningModalProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title={ (
        <div className={ styles['warning-modal-header'] }>
          <i className="icon-qbo-error font-28" />
          <span>
            Cannot
            { title }
          </span>
        </div>
      ) }
    >
      <Modal.Body>
        <div className={ styles['warning-modal-content'] }>
          <p>
            This
            {' '}
            { documentType }
            {' '}
            cannot be
            {' '}
            { actionType }
            {' '}
            as the
            {' '}
            { actionDateTitle }
            {' '}
            is dated before
            the Books Lock Date
            {' or the Payment Account start date' }
            .
          </p>

          <div className={ styles['form-group'] }>
            <span>Books Lock Date:</span>
            <input
              readOnly
              className={ styles['form-control'] }
              placeholder="MM/DD/YYYY"
              type="text"
              value={ reconciliationClosingDateStr }
            />
          </div>

          <div className={ styles['form-group'] }>
            <span>Payment Account start date:</span>
            <input
              readOnly
              className={ styles['form-control'] }
              placeholder="MM/DD/YYYY"
              type="text"
              value={ paymentAccountStartDateStr }
            />
          </div>

          <div className={ styles['form-group'] }>
            <span>
              { actionDateTitle }
              :
            </span>
            <input
              readOnly
              className={ styles['form-control'] }
              placeholder="MM/DD/YYYY"
              type="text"
              value={ actionDateStr }
            />
          </div>

          <p className={ styles['warning-note'] }>
            Books Lock Date is the date of closing books on Docyt. Reconciliation center will only
            surface transactions after this date in the uncategorized transactions section. Prior to
            this date, Invoices cannot be paid and receipt cannot be approved.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-blue"
          fontSize={ 16 }
          title="Done"
          onClick={ onCancel }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useQuickbooksWarningModal = makeUseModal(QuickbooksWarningModal);
export default useQuickbooksWarningModal;
