import React from 'react';

import { SendbirdProvider } from '@sendbird/uikit-react/SendbirdProvider';

import { useCreateSessionToken } from '@src/hooks/queries/ai_chat/ai_chatbot';

import QueryStatus from '@src/components/utils/query_status';

import '@sendbird/uikit-react/dist/index.css';

interface IChatCentralSessionProps {
    children: React.ReactNode,
  }

const ChatCentralSession = ({ children }:IChatCentralSessionProps) => {
  const userId = window.Docyt.currentAdvisor.get('id');
  const sessionTokenQuery = useCreateSessionToken();

  return (
    <>
      <QueryStatus query={ sessionTokenQuery } />
      {sessionTokenQuery.data?.sessionToken && (
        <SendbirdProvider
          accessToken={ sessionTokenQuery.data.sessionToken }
          appId={
            window.configData.ai_chatbot.app_id
          }
          uikitOptions={ {
            groupChannel: {
              enableTypingIndicator: true,
            },
          } }
          userId={ String(userId) }
        >
          {children}
        </SendbirdProvider>
      )}
    </>
  );
};

export default ChatCentralSession;
