import React, { useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { useTSDCollection } from './hooks';
import List from './list';
import Sidebar from './sidebar';

import styles from './styles.module.scss';

const AllTransactions = () => {
  const business = useBusinessContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [rowData, setRowData] = useState<ITransactionServiceDocument | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const tsdCollection = useTSDCollection({ businessId: business.id, excluded: false });

  const handleRowClick = async (data: ITransactionServiceDocument) => {
    setIsLoading(true);

    const pages = tsdCollection.query.data?.pages || [];
    const updatedData = pages.flatMap((page) => page.collection).find(
      (item: ITransactionServiceDocument) => item.id === data.id,
    );

    setRowData(updatedData || data);

    setIsLoading(false);
    setIsSidebarOpen(true);
  };

  const handleRefreshData = async () => {
    await tsdCollection.query.refetch();
  };

  return (
    <div className={ styles.container }>
      <DetailsRegion>
        <DetailsRegion.Header title="All Transactions" />
        <div className={ styles['tables-container'] }>
          <List collection={ tsdCollection } onRowClick={ handleRowClick } />
        </div>
      </DetailsRegion>
      { isSidebarOpen && rowData && (
        <Sidebar
          key={ Math.random() }
          businessId={ business.id }
          isLoading={ isLoading }
          rowData={ rowData }
          onClose={ () => setIsSidebarOpen(false) }
          onRefreshData={ handleRefreshData }
        />
      )}
    </div>
  );
};

export default React.memo(AllTransactions);
