import React, { useRef } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useInfiniteRegionScroll } from '@src/hooks/scroll';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface IInfiniteScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  wrapperClassName?: string,
  infiniteQuery: UseInfiniteQueryResult<any, Error>,
}

const InfiniteRegionScrollTable = ({
  children,
  infiniteQuery,
  wrapperClassName,
  ...tableProps
}: IInfiniteScrollTableProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useInfiniteRegionScroll({
    elementRef: wrapperRef,
    query:      infiniteQuery,
  });

  return (
    <div
      ref={ wrapperRef }
      className={ wrapperClassName }
    >
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus noSpinner={ !infiniteQuery?.isFetchingNextPage } query={ infiniteQuery } />
    </div>
  );
};

export default React.memo(InfiniteRegionScrollTable);
