import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreatePaymentAccount } from '@src/hooks/queries/payment_accounts';
import useBusinessFeatureFlag from '@src/hooks/use_business_feature_flag';
import { TAccountType, TTransactionImportType } from '@src/types/common';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { WarningTriangleWhiteIcon } from '@src/components/utils/icomoon';

import { useConfirmLastFourModal } from '../confirm_last_four_modal';
import { ISetupAccountData } from './schema';
import Form from './setup_account_form';

type TOpenValue = {
  accountMode: TTransactionImportType;
  accountType: TAccountType,
}

interface ISetupAccountProps extends IUseModalProps<TOpenValue> {
  isBlankPage?: boolean,
}

const SetupAccount = ({
  isBlankPage,
  openValue,
  isOpen,
  onCancel,
  onDone,
}: ISetupAccountProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [setupAccountData, setSetupAccountData] = useState<
  ISetupAccountData | undefined>(undefined);

  const { accountType, accountMode } = openValue || {};
  const business = useBusinessContext();
  const create = useCreatePaymentAccount();

  const mappingBankAccountsReconciliationCenter = useBusinessFeatureFlag(
    window.Docyt.Common.Constants.MAPPING_BANK_ACCOUNTS_RECONCILIATION_CENTER,
    business.id,
  );

  const { mutate: createAccount } = create;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleDone = useCallback(() => {
    onDone();
    if (isBlankPage) { window.location.reload(); }
  }, [isBlankPage, onDone]);

  const handleCreateAccount = useCallback((data?: ISetupAccountData) => {
    if (data === undefined) return;

    const isCredit = accountType === 'credit_card';

    createAccount({
      businessId:                        business.id,
      financialInstitutionBankAccountId: data.bankAccount?.bankAccountId,
      paymentAccount:                    {
        accountType,
        cvv:                   isCredit ? data.cvv : undefined,
        last4:                 data.last4,
        name:                  data.name,
        plaidPublicToken:      data.plaidPublicToken,
        plaidAccountId:        data.plaidAccountId,
        ...(mappingBankAccountsReconciliationCenter ? {} : { qboId: data.qbo?.id, qboName: data.qbo?.name }),
        routingNumber:         isCredit ? undefined : data.routingNumber,
        startImportDate:       data.startImportDate,
        startBalance:          data.startBalance,
        transactionImportType: accountMode,
      },
    }, {
      onSuccess: handleDone,
      onError:   handleError,
    });
  }, [accountMode, accountType, business.id, mappingBankAccountsReconciliationCenter, createAccount, handleDone, handleError]);

  const confirmLast4Modal = useConfirmLastFourModal({
    onDone: () => handleCreateAccount(setupAccountData),
  });

  const handleSubmit = useCallback((data: ISetupAccountData) => {
    if (!accountType) return;

    if (accountMode === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.AUTO
      && data.bankAccount?.bankAccountLast4 !== data.last4) {
      setSetupAccountData(data);
      confirmLast4Modal.open();
    } else {
      handleCreateAccount(data);
    }
  }, [accountMode, accountType, confirmLast4Modal, handleCreateAccount]);

  return (
    <>
      <confirmLast4Modal.Component { ...confirmLast4Modal.props } />
      <SideView.Form
        doneTitle={ accountMode === 'auto' ? 'Save' : 'Done' }
        isOpen={ isOpen }
        title="Add New Account"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <div
            className="document-fields setup-manual-account-view setup-manual-bank-account-view"
          >
            {
              create.isLoading && <Spinner />
            }
            {
              errorMessage && (
                <SideView.Error>
                  <WarningTriangleWhiteIcon fontSize={ 18 } />
                  <div className="error-message">
                    <span>Account could not be added.</span>
                    <span>{ errorMessage }</span>
                  </div>
                </SideView.Error>
              )
            }
            <SideView.Subtitle>
              { accountMode === 'auto' && (<span className="font-normal font-14">Add a new banking account and map it to a financial institution account.</span>) }
              { accountMode !== 'auto' && (accountType === 'bank' ? 'Bank Account' : 'Credit Card') }
            </SideView.Subtitle>
            { accountMode !== 'auto' && (<hr />) }
            <Form
              accountMode={ accountMode! }
              accountType={ accountType! }
              business={ business }
              formId={ formId }
              mappingBankAccountsReconciliationCenter={ Boolean(mappingBankAccountsReconciliationCenter) }
              onSubmit={ handleSubmit }
            />
          </div>
        ) }
      </SideView.Form>
    </>
  );
};

const MemoizedSetupAccount = React.memo(SetupAccount);
const useSetupAccount = makeUseModal<typeof MemoizedSetupAccount, TOpenValue>(MemoizedSetupAccount);

export {
  useSetupAccount,
  SetupAccount as default,
};
