import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createPortfolio, getPortfolioList } from '@src/requests/portfolio_service/portfolio';
import { ICreatePortfolioParams, IPortfolioData } from '@src/types/portfolio';

const useCreatePortfolio = () => {
  return useMutation<IPortfolioData, Error, ICreatePortfolioParams>(createPortfolio);
};

const useGetPortfolio = () => {
  return useQuery<IPortfolioData, Error>(
    [QueryKey.portfolioList],
    () => getPortfolioList(),
  );
};

export {
  useCreatePortfolio,
  useGetPortfolio
};
