import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  useGroupSimilarTsds,
  useUpdateTsdFlaggedState,
} from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';

import useProgressModal from './progress_modal';

interface IConfirmModalProps extends IUseModalProps {
  selectedRecords: ITransactionServiceDocument[],
  updateToFlaggedState: null | string
}

const stateMappings:{ [key: string]: { title: string; content: string } } = {
  resolved: {
    title:   'Mark as Reviewed',
    content: 'issues have been selected and will be marked as reviewed.',
  },
  open: {
    title:   'Mark Transactions for Client Review',
    content: 'transactions have been selected and will be marked for client review.',
  },
  null: {
    title:   'Close issues',
    content: 'issues have been selected and will be marked as closed.',
  },
};

const ConfirmModal = ({
  isOpen,
  onDone: closeConfirmModal,
  onCancel,
  selectedRecords,
  updateToFlaggedState,
}: IConfirmModalProps): JSX.Element => {
  const progressModal = useProgressModal();
  const queryClient = useQueryClient();
  const updateState = useUpdateTsdFlaggedState();
  const { mutateAsync } = updateState;
  const [doneCount, setDoneCount] = useState(0);
  const { title, content } = stateMappings[String(updateToFlaggedState)];
  const { mutateAsync: groupSimilarTsds } = useGroupSimilarTsds();

  const onConfirm = async () => {
    closeConfirmModal();
    progressModal.open();
    try {
      const updatePromises = selectedRecords
        .map((i) => mutateAsync({ ...i, flaggedState: updateToFlaggedState }).then(() => {
          setDoneCount((count) => count + 1);
        }));
      await Promise.all(updatePromises);
      if (updateToFlaggedState === 'open' && selectedRecords.length > 1) {
        await groupSimilarTsds({ ids: selectedRecords.map((i) => i.id) });
      }
      await queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      toastr.success('Successfully updated', 'Success');
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to update: ${errorMessage}`, 'Error');
    } finally {
      progressModal.props.onDone();
      setDoneCount(0);
    }
  };

  return (
    <>
      <Modal
        show={ isOpen }
        title={ title }
      >
        <Modal.Body>
          <Text as="div">
            { selectedRecords.length }
            {' '}
            { content }
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-link"
            variant="link"
            onClick={ onCancel }
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={ onConfirm }
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ selectedRecords.length }
        { ...progressModal.props }
      />
    </>
  );
};

const useConfirmModal = makeUseModal(ConfirmModal);

export default useConfirmModal;
