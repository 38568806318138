import React from 'react';

import styles from '../styles.module.scss';

interface IDetailsRegionFooterHintProps {
  children: React.ReactNode;
}

const DetailsRegionFooterHint = ({ children }: IDetailsRegionFooterHintProps) => {
  return <p className={ styles['hint-content'] }>{ children }</p>;
};

export default React.memo(DetailsRegionFooterHint);
