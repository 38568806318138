import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IUpdateDashboardUsersParams,
  updateDashboardUsers,
} from '@src/requests/dashboards/dashboard_users';

const useUpdateDashboardUsers = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateDashboardUsersParams>(
    updateDashboardUsers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.dashboardUsers);
        queryClient.invalidateQueries(QueryKey.portfolioDashboard);
        queryClient.invalidateQueries(QueryKey.managementGroupDashboard);
      },
    },
  );
};

export {
  useUpdateDashboardUsers,
};
