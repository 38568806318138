import React, { useCallback, useMemo } from 'react';

import moment from 'moment';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { formatApiDate } from '@src/utils/date_helpers';

import Modal from '@src/components/ui/modal';

import Form from './form';
import { ISetupAccountData } from '../schema';

type TOpenValue = {
  data: ISetupAccountData;
}

interface IConfirmImportStartingDateModalProps extends IUseModalProps<TOpenValue> {
  onConfirm: (data: ISetupAccountData) => void,
}

const ConfirmImportStartingDateModal = ({
  isOpen,
  openValue,
  onConfirm,
  onCancel,
}: IConfirmImportStartingDateModalProps) => {
  const { data } = openValue || {};

  const handleSubmit = useCallback(() => {
    if (!data) return;

    onConfirm(data);
    onCancel();
  }, [data, onCancel, onConfirm]);

  const handleChangedStartDate = useCallback((value) => {
    if (data) {
      data.plaidStartImportDate = value;
    }
  }, [data]);

  const startImportDate = useMemo(() => {
    if ((data?.reconciliationPaymentAccount?.transactionDocumentsCount || 0) > 0
      && data?.reconciliationPaymentAccount?.lastTransactionDate) {
      return formatApiDate(moment(data?.reconciliationPaymentAccount.lastTransactionDate).add(1, 'days').toDate());
    }

    return data?.reconciliationPaymentAccount?.startImportDate;
  }, [data?.reconciliationPaymentAccount]);

  return (
    <Modal.Standard
      proceedTitle="Confirm"
      show={ isOpen }
      title="Confirm import starting date"
      onCancel={ onCancel }
      onProceed={ handleSubmit }
    >
      {() => (
        <Form
          lastTransactionDate={ data?.reconciliationPaymentAccount?.lastTransactionDate }
          startImportDate={ startImportDate }
          onChangedStartDate={ handleChangedStartDate }
        />
      )}
    </Modal.Standard>
  );
};

const MemoizedConfirmImportStartDate = React.memo(ConfirmImportStartingDateModal);
const useConfirmImportStartingDateModal = makeUseModal<
  typeof MemoizedConfirmImportStartDate, TOpenValue>(MemoizedConfirmImportStartDate);

export {
  IConfirmImportStartingDateModalProps,
  useConfirmImportStartingDateModal,
  ConfirmImportStartingDateModal as default,
};
