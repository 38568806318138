import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetFinancialInstitutionConnectionResponseInfinite } from '@src/requests/financial_institution_connection';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import Table from '@src/components/ui/table';

import Item from './financial_institution_connection_item';

interface IFinancialInstitutionConnectionTableProps {
  query: UseInfiniteQueryResult<IGetFinancialInstitutionConnectionResponseInfinite, Error>,
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  refetchFinancialConenctionList: () => void
}

const FinancialInstitutionConnectionTable = ({
  query,
  financialInstitutionConnections,
  refetchFinancialConenctionList,
}: IFinancialInstitutionConnectionTableProps) => {
  return (
    <Table
      className="finacial-institution-connection-center-table"
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 20 }>Financial Institution Account</Table.HCell>
          <Table.HCell widthPercent={ 10 }>Last 4 digits</Table.HCell>
          <Table.HCell widthPercent={ 10 }>Management Groups</Table.HCell>
          <Table.HCell widthPercent={ 50 }>Banking Account</Table.HCell>
          <Table.HCell widthPercent={ 5 }>Connection</Table.HCell>
          <Table.HCell widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {financialInstitutionConnections.length > 0 ? (
          <>
            {
            financialInstitutionConnections.map((financialInstitutionConnection) => (
              <Item
                key={ financialInstitutionConnection.id }
                financialInstitutionConnection={ financialInstitutionConnection }
                refetchFinancialConenctionList={ refetchFinancialConenctionList }
              />
            ))
          }
          </>
        ) : (
          <Table.Row>
            {!query.isFetching && (
              <Table.Cell align="center" colSpan={ 6 }>
                No connection available in this management group
              </Table.Cell>
            )}
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default React.memo(FinancialInstitutionConnectionTable);
