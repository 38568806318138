import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const SplitAcrossBusinessesIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="split_across_businesses"
    />
  );
};

export default SplitAcrossBusinessesIcon;
