import React from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';
import { IMultiReportDetailBusiness } from '@src/types/report_service/multi_report_detail';

import { Button } from '@src/components/ui_v2/buttons';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import Action from '../actions/portfolio_list_item_action';

import styles from '../styles.module.scss';

interface IPortfolioListItemProps {
  action: string,
  businesses: IBusiness[],
  item: IMultiBusinessReportDetail,
  setAction: React.Dispatch<React.SetStateAction<string>>,
}

const PortfolioListItem = ({ action, businesses, item, setAction }: IPortfolioListItemProps) => {
  return (
    <div className={ styles['list-item'] }>
      <Button
        className={ styles['title-content'] }
        variant="link"
        onClick={ () => {
          Backbone.history.navigate(`/multi_entity_reports/custom_reports/${item.id}`, { trigger: true, replace: false });
        } }
      >
        <p>{ item.name }</p>
      </Button>
      <div className={ styles['last-updated'] }>
        <p className={ styles['status-txt'] }>{ item.lastUpdatedDate ? `Updated ${item.lastUpdatedDate}` : '' }</p>
      </div>
      <div className={ styles['shared-with'] }>
        <div className={ styles['status-item'] }>
          <div className={ styles['avatar-item'] }>
            { item.businesses.map((business: IMultiReportDetailBusiness) => (
              <React.Fragment key={ business.id }>
                { business.avatar ? (
                  <img
                    alt="Business avatar"
                    className={ styles.avatar }
                    src={ business.avatar.avatarUrl }
                  />
                ) : (
                  <BusinessDefaultAvatarIcon fontSize={ 30 } />
                ) }
              </React.Fragment>
            )) }
          </div>
        </div>
      </div>
      <div className={ styles.action }>
        <Action
          action={ action }
          businesses={ businesses }
          item={ item }
          setAction={ setAction }
        />
      </div>
    </div>
  );
};

export default React.memo(PortfolioListItem);
