import ActionsArea from './actions_area';
import Breadcrumb from './breadcrumb';
import Breadcrumbs from './breadcrumbs';
import BulkActions from './bulk_actions';
import BulkAction from './bulk_actions/action';
import BulkActionsDropdown from './bulk_actions/dropdown';
import SelectedItems from './bulk_actions/selected_items';
import HeaderRegion from './header_region';
import Tab from './tab';
import Tabs from './tabs';
import Title from './title';

export default Object.assign(HeaderRegion, {
  Title,
  Tabs,
  Tab,
  Breadcrumb,
  Breadcrumbs,
  ActionsArea,
  BulkActions,
  SelectedItems,
  BulkActionsDropdown,
  BulkAction,
});
