import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const SplitInvoicesIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="split_to_invoices"
    />
  );
};

export default SplitInvoicesIcon;
