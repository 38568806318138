import React, { useEffect } from 'react';

import { useGetReportById } from '@src/hooks/queries/report_service/custom_reports';
import { TMongoID } from '@src/types/common';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import { CrossIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import { useRefreshLogsCollection } from './hooks';
import Table from './table';

interface IReportRefreshLogsProps {
  reportId: TMongoID,
}

const ReportRefreshLogs = ({ reportId }: IReportRefreshLogsProps) => {
  const { data, refetch: refetchReportData, isLoading } = useGetReportById(reportId);
  
  useEffect(() => {
    if(!data){
      refetchReportData()
    }
  },[data, refetchReportData]);

  const collection = useRefreshLogsCollection(reportId);
  
  if(isLoading){
    return (
      <div className='spinner-open'>
        <div className='spinner-overlay'><i className='fa fa-spinner fa-pulse' /></div>
      </div>
    )
  }

  return (
    <div className="report-refresh-logs">
      <div className="header">
        <div className="empty-box" />
        <h2 className="header-title">{`${data?.name || ''} Refresh Log`}</h2>
        <div
          aria-label="Close Page"
          role="button"
          tabIndex={ 0 }
          onClick={ () => Backbone.history.history.back() }
        >
          <CrossIcon fontSize={ 24 } />
        </div>
      </div>
      <Section.Provider section={ collection.section }>
        <DetailsRegion>
          <TableSection>
            <Table
              records={ collection.records }
              sorting={ collection.sorting }
            />
          </TableSection>
        </DetailsRegion>
      </Section.Provider>
    </div>
  );
};

export default React.memo(ReportRefreshLogs);
