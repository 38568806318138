module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, businessColumn, business_column, column, filter, i, index, j, k, l, len, len1, len2, len3, len4, len5, len6, len7, len8, len9, m, month, n, o, p, q, quarterly, r, ref, ref1, ref10, ref11, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, s, t, year;
    
      __out.push('<div class="item-divider m-b-20">\n  <div class="display-flex align-items-center m-t-6 m-b-6">\n    <div class="documents-cat-header font-14 font-semi-bold m-r-auto">\n      <a class="text-decoration-blank m-l-6 in-blue-400" href="/multi_entity_reports/custom_reports">Portfolio Reports </a>\n      <div class="client__breadcrumbs-item font-18 in-blue-400"></div>\n      <span class="font-bold font-26">');
    
      __out.push(__sanitize(this.multi_business_report.get('name')));
    
      __out.push('</span>\n    </div>\n    ');
    
      if (this.multi_business_report.isDailyReport()) {
        __out.push('\n      <div class="permission-dropdown-wrapper width-150px">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n          <span class="period-label-js">Daily</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n          <div class="bank-account-list">\n          <li class="period-daily-js" data-value=\'daily\'>\n              <span class=\'text m-t-3 m-l-10\'>Daily</span>\n          </li>\n          <li class="period-monthly-js" data-value=\'flagged\'>\n              <span class=\'text m-t-3 m-l-10\'>Monthly</span>\n          </li>\n          </div>\n        </ul>\n      </div>\n    ');
      }
    
      __out.push('\n    <div class="line-height-30 display-flex m-l-20 text-center business-item-avatars business-items">\n      ');
    
      ref = this.multi_business_report.get('businesses');
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        business = ref[index];
        __out.push('\n        ');
        if (index < 5) {
          __out.push('\n          ');
          if (business.avatar) {
            __out.push('\n            <div class="middle-avatar-wrapper border-gray business-item-avatar multi-report-business-item-avatar" style="cursor: default;">\n              <img src="');
            __out.push(__sanitize(business.avatar.avatar_url));
            __out.push('">\n            </div>\n          ');
          } else {
            __out.push('\n            <span class=\'icon-business-default-avatar font-30 business-item-avatar\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.multi_business_report.get('businesses').length > 5) {
        __out.push('\n        <span class=\'icon-business-default-avatar business-item-avatar text-center display-flex font-30\'><span class=\'path1\'></span><span class="count">+');
        __out.push(__sanitize(this.multi_business_report.get('businesses').length - 5));
        __out.push('</span></span>\n      ');
      }
    
      __out.push('\n    </div>\n    <div class="relative">\n      <a class="btn" type="button" id="custom-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-ellipsis-h font-24 in-grey-800"></span>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="custom-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n        <li>\n          <a class="pointer rename-btn">\n            <div class="dropdown-menu-action-item">\n              <div class=\'action-icon\'><i class="icon icon-edit action-icon"></i></div>\n              <span>Rename Report</span>\n            </div>\n          </a>\n        </li>\n        <li class="dropdown-submenu drill-down">\n          <a class="pointer">\n            <div class="dropdown-menu-action-item">\n              <div class=\'action-icon\'><i class="icon icon-Export-as-Excel action-icon"></i></div>\n              <span>Export as Excel</span>\n            </div>\n          </a>\n          <ul class="dropdown-menu" style="border-radius: 6px 0 6px 6px;">\n            <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
    
      __out.push(__sanitize(true));
    
      __out.push('" style="padding: 10px 20px;">Include drill down</a></li>\n            <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
    
      __out.push(__sanitize(false));
    
      __out.push('" style="padding: 10px 20px;">Exclude drill down</a></li>\n          </ul>\n        </li>\n      </ul>\n    </div>\n  </div>\n</div>\n<div id="export-data-baneer"></div>\n');
    
      if (this.isUpdating) {
        __out.push('\n  <div class="report-status-panel text-left display-flex align-items-center">\n    <label class="report-status-label m-l-10 m-b-0"> ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['CUSTOM_REPORT_DATA_UPDATING_STATE']));
        __out.push(' </label>\n  </div>\n');
      }
    
      __out.push('\n<div class="main-container custom-reports-table-border m-t-18">\n  <div class=\'display-flex align-items-center padding-10\'>\n    <div class="filter-group filter-name m-l-10 m-r-10">\n      <input type="text" class="form-control filter-name-js" placeholder="Search by Name" />\n    </div>\n    <div class="m-r-10">\n      <div class="filter-input-group filter-input-group-current">\n        <input type="text" class="filter-date-input date-picker form-control filter-current-date" placeholder="Select Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.currentDuration));
    
      __out.push('"/>\n      </div>\n      <div class="filter-input-group filter-input-group-range">\n        <input type="text" class="filter-date-input date-picker form-control filter-start-date" placeholder="From" autocomplete="off" value="');
    
      __out.push(__sanitize(this.startDuration));
    
      __out.push('"/>\n        <span class="input-group-addon">-</span>\n        <input type="text" class="filter-date-input date-picker form-control filter-end-date" placeholder="To" autocomplete="off" value="');
    
      __out.push(__sanitize(this.endDuration));
    
      __out.push('"/>\n        <a class="btn dropdown-toggle range-select-menu-btn pointer" type="button" id="filter-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n          <span class="fa fa-caret-down font-14"></span>\n        </a>\n        <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="filter-dropdown-btn">\n          <li class="font-14 dropdown-submenu">\n            <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n              <span>Calendar month</span>\n            </a>\n            <ul class="dropdown-menu col-xs-12">\n              ');
    
      ref1 = this.calendarMonth;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        month = ref1[j];
        __out.push('\n                <li><a tabindex="-1" data-id="');
        __out.push(__sanitize(this.calendarMonth.indexOf(month)));
        __out.push('" class="date-calendar-month">');
        __out.push(__sanitize(month.format('MMM Y')));
        __out.push('</a></li>\n              ');
      }
    
      __out.push('\n            </ul>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 pointer year-to-date-btn">\n              <span>Year to date</span>\n            </a>\n          </li>\n          <li class="font-14 dropdown-submenu">\n            <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n              <span>Quarterly</span>\n            </a>\n            <ul class="dropdown-menu filter-dropdown-menu">\n              ');
    
      for (year = k = ref2 = this.startYear, ref3 = this.endYear; ref2 <= ref3 ? k <= ref3 : k >= ref3; year = ref2 <= ref3 ? ++k : --k) {
        __out.push('\n                <li class="font-14 dropdown-submenu">\n                  <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n                    <span>');
        __out.push(__sanitize(year));
        __out.push('</span>\n                  </a>\n                  <ul class="dropdown-menu filter-dropdown-menu">\n                    ');
        for (quarterly = l = 0; l <= 3; quarterly = ++l) {
          __out.push('\n                      <li>\n                        <a class="dropdown-item font-14 quarterly-dropdown-item-toggle pointer" type="button"  data-prefix="');
          __out.push(__sanitize(year));
          __out.push('" data-value="');
          __out.push(__sanitize(quarterly));
          __out.push('">\n                          <span>');
          __out.push(__sanitize(this.QUARTERS[quarterly].label));
          __out.push('</span>\n                        </a>\n                      </li>                      \n                    ');
        }
        __out.push('\n                  </ul>\n                </li>\n              ');
      }
    
      __out.push('\n            </ul>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 mark-as-paid-by-cash-btn last-year-btn pointer" type="button">\n              <span>Last Year</span>\n            </a>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 mark-as-paid-by-cash-btn past-twelve-btn pointer" type="button">\n              <span>Past 12 months</span>\n            </a>\n          </li>\n        </ul>\n      </div>\n    </div>\n    <div class="permission-dropdown-wrapper column-setting-wrapper width-150px ');
    
      if (this.hideColumnFilter) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('">\n      <div class="dropdown-toggle permission-dropdown-control column-select-menu-btn" id="column-setting-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span>Customize</span>\n        <span class="fa fa-caret-down font-14"></span>\n      </div>\n      <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="column-setting-dropdown-btn">\n        ');
    
      ref4 = this.reportFilters;
      for (m = 0, len2 = ref4.length; m < len2; m++) {
        filter = ref4[m];
        __out.push('\n          ');
        if (this.showFilter(filter)) {
          __out.push('\n            <li>\n              <div class="dropdown-item checkbox pointer checkbox-primary">\n                <input class="pointer" type="checkbox" id=\'');
          __out.push(__sanitize(filter.id));
          __out.push('\' ');
          if (this.columnFilters[filter.id]) {
            __out.push('checked');
          }
          __out.push(' />\n                <label for=\'');
          __out.push(__sanitize(filter.id));
          __out.push('\' class="pointer">');
          __out.push(__sanitize(filter.name));
          __out.push(' </label>\n              </div>\n            </li>\n          ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n      </ul>\n    </div>\n    <div class="m-l-auto"></div>\n    <div class="zero-rows-hidden checkbox pointer checkbox-primary inline-block m-l-5 m-r-10 m-b-5">\n      <input class="pointer" type="checkbox" id="checkbox-hide-zero" ');
    
      __out.push(__sanitize(this.isZeroRowsHidden ? 'checked' : ''));
    
      __out.push('>\n      <label for="checkbox-hide-zero">Hide zero rows</label>\n    </div>\n  </div>\n  <div class="report-item-list multi-custom-report custom-report-item-list">\n    <div class="column-heads-wrapper font-semi-bold ');
    
      if (this.showMonthlyWiseView) {
        __out.push(__sanitize('height-120'));
      }
    
      __out.push('">\n      <div class="head-column"></div>\n      <div class="display-flex-column footer__nav">\n        ');
    
      if (this.showMonthlyWiseView) {
        __out.push('\n          <div class="main-box">\n            <div class="multiple-business-report-box">\n              ');
        ref5 = this.business_columns;
        for (index = n = 0, len3 = ref5.length; n < len3; index = ++n) {
          column = ref5[index];
          __out.push('\n                ');
          if (column.type === 'monthlyColumn') {
            __out.push('\n                  <div class="top-head column-border-right" style="flex:');
            __out.push(__sanitize(1 - this.minimumColWidth));
            __out.push('"><span>');
            __out.push(__sanitize(column.name));
            __out.push('</span></div>\n                ');
          } else {
            __out.push('\n                  <div class="top-head column-border-right" style="flex:');
            __out.push(__sanitize(this.minimumColWidth));
            __out.push('"></div>\n                ');
          }
          __out.push('\n              ');
        }
        __out.push('\n            </div>\n          </div>\n        ');
      }
    
      __out.push('\n        <div class="main-box">\n          <div class="multiple-business-report-box">\n            ');
    
      ref6 = this.business_columns;
      for (o = 0, len4 = ref6.length; o < len4; o++) {
        column = ref6[o];
        __out.push('\n              ');
        if (column.type === 'monthlyColumn') {
          __out.push('\n                ');
          ref7 = column.businessColumns;
          for (p = 0, len5 = ref7.length; p < len5; p++) {
            businessColumn = ref7[p];
            __out.push('\n                  <div class="top-head column-border-right"><span>');
            __out.push(__sanitize(businessColumn.name));
            __out.push('</span></div>\n                ');
          }
          __out.push('\n              ');
        } else {
          __out.push('\n                <div class="top-head column-border-right"><span>');
          __out.push(__sanitize(column.name));
          __out.push('</span></div>\n              ');
        }
        __out.push('\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n        <div class="main-box">\n          <div class="multiple-business-report-column">\n            ');
    
      ref8 = this.business_columns;
      for (q = 0, len6 = ref8.length; q < len6; q++) {
        business_column = ref8[q];
        __out.push('\n              ');
        if (business_column.type === 'monthlyColumn') {
          __out.push('\n                ');
          ref9 = business_column.businessColumns;
          for (r = 0, len7 = ref9.length; r < len7; r++) {
            business = ref9[r];
            __out.push('\n                  ');
            ref10 = this.filteredColumns;
            for (index = s = 0, len8 = ref10.length; s < len8; index = ++s) {
              column = ref10[index];
              __out.push('\n                    ');
              if (index === this.filteredColumns.length - 1) {
                __out.push('\n                      <div class="general-column column-border-right m-l-1"><span>');
                __out.push(__sanitize(column.name));
                __out.push('</span></div>\n                    ');
              } else {
                __out.push('\n                      <div class="general-column"><span>');
                __out.push(__sanitize(column.name));
                __out.push('</span></div>\n                    ');
              }
              __out.push('\n                  ');
            }
            __out.push('\n                ');
          }
          __out.push('\n              ');
        } else {
          __out.push(' \n                ');
          ref11 = this.filteredColumns;
          for (index = t = 0, len9 = ref11.length; t < len9; index = ++t) {
            column = ref11[index];
            __out.push('\n                  ');
            if (index === this.filteredColumns.length - 1) {
              __out.push('\n                    <div class="general-column column-border-right m-l-1"><span>');
              __out.push(__sanitize(column.name));
              __out.push('</span></div>\n                  ');
            } else {
              __out.push('\n                    <div class="general-column"><span>');
              __out.push(__sanitize(column.name));
              __out.push('</span></div>\n                  ');
            }
            __out.push('\n                ');
          }
          __out.push('\n              ');
        }
        __out.push('\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}