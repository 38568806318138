import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { useGetWithMappingAccountingClasses } from '@src/hooks/queries/accounting_classes';
import { TID } from '@src/types/common';

import useAddDepartmentModal from './add_department_modal';

interface IAddDepartmentProps {
  businessId: TID,
}

const MappedDepartment = ({
  businessId,
}: IAddDepartmentProps) => {
  const { getValues, setValue } = useFormContext();
  const [mappedIds, setMappedIds] = useState<string[]>(getValues('mappingClassIds'));

  const addDepartment = useAddDepartmentModal();

  const query = useGetWithMappingAccountingClasses({ businessId });
  const accountingClasses = useMemo(() => {
    return query.data?.accountingClasses ?? [];
  }, [query.data]);

  const handleContinue = useCallback((value) => {
    if (!value.mappingClassIds) {
      setMappedIds([]);
      setValue('mappingClassIds', []);
    } else {
      setMappedIds(value.mappingClassIds);
      setValue('mappingClassIds', value.mappingClassIds);
    }
    addDepartment.props.onDone();
  }, []);

  // if (accountingClasses.length === 0) return null;

  return (
    <>
      <addDepartment.Component
        { ...addDepartment.props }
        accountingClasses={ accountingClasses }
        mappedIds={ mappedIds }
        onDone={ handleContinue }
      />
      <div className="display-flex">
        <span style={ { flex: '0 0 30%' } }>Mapped Departments</span>
        {
          mappedIds.length === 0 ? (
            <a
              className="font-bold"
              role="button"
              style={ { marginLeft: 'auto' } }
              tabIndex={ 0 }
              onClick={ addDepartment.open }
            >
              Add Departments
            </a>
          ) : (
            <div className="display-flex width-100-percent justify-content-between">
              <div className="display-flex-column">
                {
                  mappedIds.map((id) => (
                    <span key={ id }>
                      { accountingClasses.find((item) => item.id === Number(id))?.name }
                    </span>
                  ))
                }
              </div>
              <a
                className="font-semi-bold"
                role="button"
                tabIndex={ 0 }
                onClick={ addDepartment.open }
              >
                Edit
              </a>
            </div>
          )
        }
      </div>
    </>
  );
};

export default MappedDepartment;
