import React from 'react';

import { IPortfolioDashboard } from '@src/types/portfolio';

import Header from './side_menu_header';
import List from './side_menu_list';

import styles from '../styles.module.scss';

interface IDashboardSideMenu {
  dashboard: IPortfolioDashboard,
  widgetId?: string,
}

const DashboardSideMenu = ({
  dashboard,
  widgetId,
}: IDashboardSideMenu): JSX.Element => {
  return (
    <div className={ styles['side-menu'] }>
      <Header />
      {
        widgetId && (
          <List
            dashboard={ dashboard }
            widgetId={ widgetId }
          />
        )
      }
    </div>
  );
};

export default DashboardSideMenu;
