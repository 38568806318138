import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { snakeCase } from 'lodash';
import moment from 'moment';

import { industryOptions } from '@src/constants/industry_options';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useExportReportAsExcel, useGetReportTemplates } from '@src/hooks/queries/report_service/custom_reports';
import { useCreateReportExportConfiguration } from '@src/hooks/queries/report_service/report_configurations';
import { IExportReportConfiguration } from '@src/requests/report_service/custom_reports';
import { IReport, ITemplate } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { API_DATE_FORMAT, apiMonthToDate, monthToApiEndDate, monthToApiStartDate } from '@src/utils/date_helpers';

import { MonthPickerInput } from '@src/components/ui/form';
import Modal from '@src/components/ui/modal';
import { CheckboxInput, RadioInput } from '@src/components/ui_v2/inputs';
import MultiSelect from '@src/components/ui_v2/inputs/multi_select/multi_select';
import MutationStatus from '@src/components/utils/mutation_status';

import BusinessReportsMultiSelectField from '../../common/business_multi_select_field';

interface IColumnData {
  label: string;
  value: string;
}

interface IDownloadReportConfigurationProps extends IUseModalProps {
  reportServiceId: number;
  onCreateExportData: (result: boolean) => void;
  category: string;
  reports: IReport[];
  isEdit: boolean;
  configurations: IReportConfiguration[];
  reportConfigData?: IExportReportConfiguration;
  showConfigModal: boolean;
  onUpdate: (data: any) => void;
  handleSubmit: (isChecked: boolean) => void;
}

const DownloadReportConfiguration = ({
  reportServiceId,
  category,
  isOpen,
  onCancel,
  onDone,
  onCreateExportData,
  reports,
  isEdit,
  reportConfigData,
  configurations,
  showConfigModal,
  onUpdate,
  handleSubmit,
}: IDownloadReportConfigurationProps): JSX.Element => {
  const [refreshKey, setRefreshKey] = useState(0);
  const business = useBusinessContext();
  const currentDate = new Date();
  const reportTemplatesQuery = useGetReportTemplates(
    business.standardCategoryId,
  );
  const templates = useMemo(() => reportTemplatesQuery.data || [], [
    reportTemplatesQuery,
  ]);

  const createConfig = useCreateReportExportConfiguration();
  const downloadReports = useExportReportAsExcel();

  const [industry, setIndustry] = useState<string[]>([]);
  const [selectedReportIDs, setSelectedReportIDs] = useState<string[]>([]);
  const [reportColumns, setReportColumns] = useState<IColumnData[]>([]);
  const [drillDownState, setDrillDownState] = useState<string>('');

  const [columnState, setColumnState] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filteredColumn, setFilteredColumn] = useState<IColumnData[]>([]);

  const [startDateStr, setStartDateStr] = useState<string>('');
  const [endDateStr, setEndDateStr] = useState<string>('');

  const [isChecked, setIsChecked] = useState<boolean>(true);

  useEffect(() => {
    const currentIndustry = industryOptions.filter(
      (item) => item.value === String(business.standardCategoryId),
    );
    if (currentIndustry.length > 0) {
      setIndustry([currentIndustry[0].label]);
    } else {
      setIndustry([industryOptions[0].label]);
    }
  }, [business.standardCategoryId]);

  useEffect(() => {
    if (reportConfigData) {
      setDrillDownState(
        !reportConfigData?.filterConfig?.includeDrillDown ? 'no' : 'yes',
      );
      setIsChecked(!showConfigModal);
    }
  }, [reportConfigData, showConfigModal, refreshKey]);

  /**
   * Desc: Method to get Selected Column value from filtered colum list array
   * @param data
   * @returns
   */
  const filterSelectedColumns = (data: IColumnData[]) => {
    return selectedColumns.filter(
      (col) => data.findIndex((c) => c.value === col) > -1,
    );
  };

  useEffect(() => {
    let filteredCol = [...reportColumns];
    if (columnState === '$') {
      filteredCol = reportColumns.filter((item) => item.label.includes('$'));
    } else if (columnState === '%') {
      filteredCol = reportColumns.filter((item) => item.label.includes('%'));
    }
    setFilteredColumn(filteredCol);
    const tempCol = filterSelectedColumns(filteredCol);
    if (columnState !== '') {
      setSelectedColumns(tempCol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState, reportColumns]);

  const preSelectedReports = useMemo(() => {
    if (reportConfigData && reportConfigData.filterConfig.reports.length > 0) {
      return reportConfigData.filterConfig.reports;
    }
    // Hiding option to select labor report for now. Wil remove this condition When backend is ready
    const filter = reports.filter((report: IReport) => {
      return templates.find((template: ITemplate) => (
        category !== 'labor'
        && template.id === report.templateId
        && template.category === category
      ));
    });
    return filter?.map((v: IReport) => v.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, templates, category, reportConfigData, refreshKey]);

  /**
   * Desc: Method to handle report change event
   * @param reportIds
   */
  const handleOnChangeReport = (reportIds: string[], isPreSelect = false) => {
    const configColumns = configurations[3]?.columns || [];
    setSelectedReportIDs(reportIds);
    const selectedReports = reports.filter((report) => {
      return reportIds.indexOf(report.id) > -1;
    });
    let reportsColumns: IReportColumn[] = [];
    selectedReports.forEach((item) => {
      if (item?.ptdColumns && item?.ptdColumns.length > 0) {
        reportsColumns = [
          ...reportsColumns,
          ...item.ptdColumns,
          ...item.ytdColumns,
        ];
      }
    });

    const existConfigColumn =
      configColumns.filter((col) => {
        return (
          reportsColumns.findIndex(
            (item) => item.name === col.name
              && col.year === item.year
              && col.range === item.range,
          ) > -1
        );
      }) || [];
    const data = existConfigColumn.map((col) => {
      return { label: col.name, value: col.id };
    });
    const tempColumnData = data.filter((rep) => !rep.label.includes('Budget') && !rep.label.includes('Forecast'));
    setReportColumns(tempColumnData);
    if (data.length === 0) {
      setSelectedColumns([]);
    } else if (isPreSelect && reportConfigData) {
      const tempCol = reportConfigData?.filterConfig?.columns.map((col) => snakeCase(col));
      setSelectedColumns(tempCol);
    } else {
      const tempCol = filterSelectedColumns(data);
      setSelectedColumns(tempCol);
    }
  };

  useEffect(() => {
    if (preSelectedReports.length > 0) handleOnChangeReport(preSelectedReports, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedReports, configurations, reports, refreshKey]);

  const onStartDateChange = useCallback(
    (value) => {
      if (!value) return;

      const startDate = monthToApiStartDate(value);
      setStartDateStr(startDate);

      if (endDateStr && moment(startDate).isAfter(moment(endDateStr))) {
        setEndDateStr(startDate);
      }
    },
    [setStartDateStr, endDateStr, setEndDateStr],
  );

  const onEndDateChange = useCallback(
    (value) => {
      if (!value) return;

      const endDate = monthToApiEndDate(value);
      setEndDateStr(endDate);

      if (startDateStr && moment(endDate).isBefore(moment(startDateStr))) {
        setStartDateStr(endDate);
      }
    },
    [setEndDateStr, startDateStr, setStartDateStr],
  );

  const handleCancel = useCallback(() => {
    setColumnState('');
    setStartDateStr('');
    setEndDateStr('');
    setRefreshKey((pre) => pre + 1);
    onCancel();
  }, [onCancel]);

  const isDisabled =
    (!isEdit && (!startDateStr || !endDateStr)) || selectedColumns.length === 0;

  const downloadReport = () => {
    const startDate = apiMonthToDate(startDateStr) || startDateStr;
    const endDate = moment(endDateStr).endOf('month').format(API_DATE_FORMAT);
    downloadReports.mutate(
      {
        reportServiceId,
        startDate,
        endDate,
        exportType:       'consolidated_report',
        reportIds:        selectedReportIDs,
        includeDrillDown: drillDownState !== 'no',
        columns:          selectedColumns,
      },
      { onSuccess: () => onCreateExportData(true) },
    );
    setStartDateStr('');
    setEndDateStr('');
    handleSubmit(isChecked);
    onDone();
  };

  const handleProceed = () => {
    if (isDisabled) return;

    if (isEdit || !reportConfigData) {
      if (isChecked) {
        localStorage.setItem(`hideExportSetting-${business.id}`, 'true');
      } else {
        localStorage.removeItem(`hideExportSetting-${business.id}`);
      }
    }

    const reportSetting = {
      industryId:       business.standardCategoryId,
      businessId:       business.id,
      reportIds:        selectedReportIDs,
      includeDrillDown: drillDownState !== 'no', // default true
      columnIds:        selectedColumns,
      reportServiceId,
    };

    if (isEdit) {
      onUpdate({ ...reportSetting, configId: reportConfigData?.id });
      handleSubmit(isChecked);
      onDone();
    } else if (!isEdit && reportConfigData) {
      downloadReport();
    } else {
      createConfig.mutate(reportSetting, { onSuccess: () => downloadReport() });
    }
  };

  return (
    <>
      <Modal.Standard
        isProceedDisabled={ isDisabled }
        proceedTitle={ isEdit ? 'Update' : 'Download' }
        show={ isOpen }
        title={ isEdit ? 'Report Settings' : 'Settings Preview' }
        onCancel={ handleCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="download-report-setting">
            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Industry:</span>
              <MultiSelect
                isDisabled
                showSelected
                className="export-input"
                options={ [] }
                placeholder="Select Industry"
                value={ industry }
                onChange={ () => {} }
              />
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Business(s):</span>
              <MultiSelect
                isDisabled
                showSelected
                className="export-input"
                options={ [] }
                placeholder="Select Business"
                value={ [business?.displayName || business?.name] }
                onChange={ () => {} }
              />
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Business Report(s):</span>
              <BusinessReportsMultiSelectField
                category={ category }
                className="export-input"
                kind="reportDownload"
                placeholder="Select Reports"
                preSelectedValues={ preSelectedReports }
                reports={ reports }
                slug=""
                templates={ templates }
                type=""
                onChange={ (reportIDs) => handleOnChangeReport(reportIDs) }
              />
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Include Drill-down:</span>
              <div className="check-content inputRow">
                <RadioInput
                  small
                  checked={ drillDownState === 'yes' }
                  className="m-r-30"
                  title="Yes"
                  onChange={ () => setDrillDownState('yes') }
                />
                <RadioInput
                  small
                  checked={ drillDownState === 'no' }
                  title="No"
                  onChange={ () => setDrillDownState('no') }
                />
              </div>
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Column Type:</span>
              <div className="check-content inputRow">
                <RadioInput
                  small
                  checked={ columnState === '$' }
                  className="m-r-30"
                  title="$ only"
                  onChange={ () => setColumnState('$') }
                />
                <RadioInput
                  small
                  checked={ columnState === '%' }
                  className="m-r-30"
                  title="% only"
                  onChange={ () => setColumnState('%') }
                />
                <RadioInput
                  small
                  checked={ columnState === 'both' }
                  title="Both"
                  onChange={ () => setColumnState('both') }
                />
              </div>
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Columns:</span>
              <MultiSelect
                className="export-input"
                menuClassName="dropdown-menu-fix-height"
                options={ filteredColumn }
                placeholder={ filteredColumn.length > 0 ? 'Select Columns' : 'Select Report First' }
                value={ selectedColumns }
                onChange={ setSelectedColumns }
              />
            </div>

            {!isEdit && (
              <div className="inputRow m-b-15">
                <span className="inputTitle">Select Period:</span>
                <div className="date-input-row">
                  <MonthPickerInput
                    options={ { endDate: currentDate, format: 'M yyyy' } }
                    placeholder="Start Date"
                    value={ startDateStr }
                    onChange={ onStartDateChange }
                  />
                  <span className="m-r-10" />
                  <MonthPickerInput
                    options={ { endDate: currentDate, format: 'M yyyy' } }
                    placeholder="End Date"
                    value={ endDateStr }
                    onChange={ onEndDateChange }
                  />
                </div>
              </div>
            )}

            {(isEdit || !reportConfigData) && (
              <div className="inputRow">
                <CheckboxInput checked={ isChecked } onChange={ setIsChecked } />
                <span className="m-l-12">Don&apos;t show this again</span>
              </div>
            )}
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ downloadReports } />
    </>
  );
};

const useDownloadReportConfigurationModal = makeUseModal(
  DownloadReportConfiguration,
);

export {
  IDownloadReportConfigurationProps,
  DownloadReportConfiguration as default,
  useDownloadReportConfigurationModal,
};
