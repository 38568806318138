import React from 'react';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IManagementGroup } from '@src/types/management_groups';

import Table from '@src/components/ui/table';

import FICBankAccountMappingInfo from './fic_bank_account_mapping_info';
import { useSetupAccount } from './new_bank_or_credit_card/new_account';

import styles from '../styles.module.scss';

interface IfinancialInstitutionBankAccountCenterTableItemProps {
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount,
  employeeCardFeatureFlag: boolean,
  managementGroup: IManagementGroup,
  refetchFinancialConenctionList: () => void
}

const FinancialInstitutionBankAccountCenterTableItem = ({
  financialInstitutionBankAccount,
  employeeCardFeatureFlag,
  managementGroup,
  refetchFinancialConenctionList,
}: IfinancialInstitutionBankAccountCenterTableItemProps) => {
  const setupAccount = useSetupAccount();

  const accountType = financialInstitutionBankAccount.accountType === 'credit' ? 'credit_card' : 'bank';

  return (
    <>
      <setupAccount.Component
        accountType={ accountType }
        financialConnectionBankAccount={ financialInstitutionBankAccount }
        managementGroupId={ managementGroup.id }
        refetchFinancialConenctionList={ refetchFinancialConenctionList }
        { ...setupAccount.props }
      />
      <Table.Row className={ styles['financial-banking-accounts-item'] }>
        <Table.Cell>
          { financialInstitutionBankAccount.name }
        </Table.Cell>
        <Table.Cell>
          { financialInstitutionBankAccount.last4 }
        </Table.Cell>
        <Table.Cell />
        <FICBankAccountMappingInfo
          accountType={ accountType }
          employeeCardFeatureFlag={ employeeCardFeatureFlag }
          financialInstitutionBankAccount={ financialInstitutionBankAccount }
          setupAccount={ setupAccount }
        />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
    </>
  );
};

export default React.memo(FinancialInstitutionBankAccountCenterTableItem);
