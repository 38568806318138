import { TID } from '@src/types/common';
import { IIntegrationsRunDownloadUrl, IIntegrationsRun } from '@src/types/integrations/runs';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from '../helpers';

interface IGetIntegrationsRunDownloadUrlsParams {
  integrationId: TID,
  integrationRunId: TID,
}

interface IGetIntegrationsRunDownloadUrlsResponse {
  collection: IIntegrationsRunDownloadUrl[]
}

interface IGetIntegrationsRunsParams {
  integrationId: TID,
  startDate?: string,
  endDate?: string,
}

interface IGetIntegrationsRunsResponse {
  collection: IIntegrationsRun[]
}

const getIntegrationsRunDownloadUrls = (
  params: IGetIntegrationsRunDownloadUrlsParams,
) : Promise<IGetIntegrationsRunDownloadUrlsResponse> => {
  return apiGet(
    `/integrations/api/v1/runs/${params.integrationRunId}/download_urls`,
    underscoreKeys({ integrationId: params.integrationId }),
  ).then((data) => {
    return {
      collection: camelizeKeys(data.download_urls) as IIntegrationsRunDownloadUrl[],
    };
  });
};

const postResetIntegrationsRun = (
  params: IGetIntegrationsRunsParams,
) : Promise<void> => {
  return apiPost(
    `/integrations/api/v1/runs/restart_batch`,
    underscoreKeys(params),
  );
};
const getIntegrationsRuns = (
  params: IGetIntegrationsRunsParams,
) : Promise<IGetIntegrationsRunsResponse> => {
  return apiGet(
    `/integrations/api/v1/integrations/${params.integrationId}/runs`,
    underscoreKeys({ start_date: params.startDate, end_date: params.endDate }),
  ).then((data) => {
    return {
      collection: camelizeKeys(data) as IIntegrationsRun[],
    };
  });
};


export {
  IGetIntegrationsRunDownloadUrlsParams,
  IGetIntegrationsRunDownloadUrlsResponse,
  getIntegrationsRunDownloadUrls,
  IGetIntegrationsRunsParams,
  postResetIntegrationsRun,
  IGetIntegrationsRunsResponse,
  getIntegrationsRuns,
};
