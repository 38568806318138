import React, { useState } from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { TID } from '@src/types/common';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import {
  FlaggedTransactionsIcon,
  DocumentRequestsIcon,
} from '@src/components/utils/icomoon';

interface IOpenItemsSidebarActionProps {
  businessId: TID,
}

const OpenItemsSidebarAction = ({
  businessId,
}: IOpenItemsSidebarActionProps): JSX.Element => {
  const { data: info } = useGetBusinessServicesInfo(businessId);

  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }
  
  return (
    <ServiceSidebar>
      {(info?.isAccountingFirmUser || (info?.canAccessTaskAdvisor && info?.documentRequestServiceRole)) && (
        <ServiceSidebar.Item
          badge={info?.countDocumentRequests}
          href={`/businesses/${businessId}/open_items/document_requests`}
          icon={<DocumentRequestsIcon />}
          isHidden={!sunsetClientCentralEnabled}
          title="Document Requests"
        />
      )}
      {
        info?.isBusinessMember && info?.hasFlaggedTransactionsAdvisorRole && (
          <ServiceSidebar.Item
            badge={info?.countOpenedItems}
            href={`/businesses/${businessId}/open_items/flagged_transactions`}
            icon={<FlaggedTransactionsIcon />}
            title="Flagged Transactions"
          />
        )
      }
    </ServiceSidebar>
  );
};

export default OpenItemsSidebarAction;
