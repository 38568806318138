import React from 'react';

import compact from 'lodash/compact';
import pluralize from 'pluralize';

import { Button } from '@src/components/ui_v2/buttons';

import { IAddAdjustmentValues } from '../schema';

interface ICategorySplitsValueProps {
  value: IAddAdjustmentValues['categorySplits'],
  onEdit: () => void,
  summaryTitle?: string,
}

const CategorySplitsValue = ({
  value,
  onEdit,
  summaryTitle,
}: ICategorySplitsValueProps): JSX.Element => {
  if (!value || value.length === 0 || value.every((item) => !item.chartOfAccountId)) {
    return <Button style={ summaryTitle === 'Invoice' ? { marginLeft: '70px', fontWeight: 400, whiteSpace: 'nowrap', color: 'rgb(33, 150, 243)' } : {} } variant="link" onClick={ onEdit }>Select Category</Button>;
  }

  if (value.length === 1) {
    const categoryName = compact([
      value[0].accountingClassName,
      value[0].chartOfAccountName,
    ]).join(' | ');

    return (
      <span style={ summaryTitle === 'Invoice' ? { display: 'flex', flexDirection: 'column', gap: '10px', width: '280px', fontWeight: 400, marginLeft: '28px' } : {} }>
        { categoryName }
        <br />
        <Button style={ summaryTitle === 'Invoice' ? { color: 'rgb(33, 150, 243)', fontWeight: 400 } : {} } variant="link" onClick={ onEdit }>Edit</Button>
      </span>
    );
  }

  return (
    <Button style={ summaryTitle === 'Invoice' ? { marginLeft: '70px', color: 'rgb(33, 150, 243)', fontWeight: 400 } : {} } variant="link" onClick={ onEdit }>
      { `${value.length} ${pluralize('Split', value.length)}` }
    </Button>
  );
};

export default React.memo(CategorySplitsValue);
