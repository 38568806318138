import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import DownloadIcon from '@src/components/reconciliation_center/month_end/download_action/download_icon';
import FooterContainer from '@src/components/reconciliation_center/month_end/drawers/footer_container';
import inDrawer from '@src/components/reconciliation_center/month_end/drawers/in_drawer';
import TotalAmount from '@src/components/reconciliation_center/month_end/drawers/total_amount';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section';
import { useSection } from '@src/components/utils_v2/section';

import { useCollection } from './hooks';
import Table from './table';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface Props {
  items: IBankAccountReconciliationItemTypes;
}

const Component: FC<Props> = ({ items }) => {
  const business = useBusinessContext();
  const section = useSection();

  const queryParams = {
    item_id: [
      items.unclearedTransfers?.id.toString(),
      items.unclearedTransfersCarriedOver?.id.toString(),
    ],
  };

  const collection = useCollection({
    businessId: business.id,
    params:     queryParams,
    section,
  });

  const pages = collection.query.data?.pages || [];
  const totalAmount = pages[0]?.meta?.totalAmount;

  return (
    <DetailsRegion>
      <div className={ styles.content }>
        <TableSection>
          <Table
            infiniteQuery={ collection.query }
            queryParams={ queryParams }
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </TableSection>
      </div>
      <FooterContainer>
        <TotalAmount amount={ totalAmount } />
      </FooterContainer>
    </DetailsRegion>
  );
};

const Controls: FC<Props> = ({ items }) => {
  const queryParams = {
    item_id: [
      items.unclearedTransfers?.id.toString(),
      items.unclearedTransfersCarriedOver?.id.toString(),
    ],
  };

  return (
    <DownloadIcon
      exportParams={ queryParams as object }
      fontSize={ 18 }
      inColor="black"
      type="uncleared_transfers"
    />
  );
};

export default inDrawer(Component, Controls, {
  title:       'Uncleared Transfers',
  sectionName: 'month-end-uncleared-transfers',
});
