import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { useDetailsRegion } from '../details_region/hooks';

import styles from './styles.module.scss';

interface IBannerRegionProps extends React.ComponentPropsWithoutRef<'div'> {}

const BannerRegion = ({ children, className, ...props }: IBannerRegionProps) => {
  const refElement = useRef<HTMLDivElement>(null);

  const classes = classNames(styles['banner-region'], className);
  const { setBannerHeight } = useDetailsRegion();

  useEffect(() => {
    if (refElement.current) {
      setTimeout(() => {
        if (refElement.current) {
          setBannerHeight(refElement.current.clientHeight);
        }
      }, 50);
    }
  }, [setBannerHeight]);

  return (
    <div ref={ refElement } className={ classes } { ...props }>
      <div className={ styles['banner-container'] }>
        { children }
      </div>
    </div>
  );
};

export default React.memo(BannerRegion);
