import React, { ReactNode, useCallback } from 'react';

import classNames from 'classnames';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui_v2/dropdown';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { ThreeDotsIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IActionsDropDownProps extends IUIStyleProps {
  children?: ReactNode,
  className?: string,
  toggleButton?: ReactNode,
  classNameMenus?: string,
  title?: string,
  icon?: ReactNode,
  isShowArrow?: boolean,
}

const ActionsDropdown = ({
  children,
  toggleButton,
  className,
  classNameMenus,
  icon,
  title,
  isShowArrow = false,
  ...props
}: IActionsDropDownProps): JSX.Element => {
  const classes = classNames(className, {
    [styles['actions-dropdown-toggle-button']]: !icon && !title,
    [styles['actions-dropdown-toggle-button-with-icon-text']]: icon || title,
  });

  const renderToggleButton = useCallback(() => {
    return (
      <Dropdown.ToggleButton className={ classes }>
        { icon || title ? (
          <>
            { icon }
            { title && (<span>{ title }</span>) }
          </>
        ) : (
          <ThreeDotsIcon />
        ) }

        { isShowArrow && <CaretIcon variant="down" /> }
      </Dropdown.ToggleButton>
    );
  }, [classes, icon, title, isShowArrow]);

  return (
    <Dropdown.Provider { ...props }>
      { toggleButton || renderToggleButton() }
      <Dropdown.Menu className={ classNameMenus }>{ children }</Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export default ActionsDropdown;
