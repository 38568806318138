import React from 'react';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';

import { Button as ButtonLink } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { InfoIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface BankAccountMappingInfoProps {
  accountType: string;
  employeeCardFeatureFlag: boolean;
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount;
  setupAccount: {
    open: () => void;
  };
}

const FICBankAccountMappingInfo: React.FC<BankAccountMappingInfoProps> = ({
  accountType,
  employeeCardFeatureFlag,
  financialInstitutionBankAccount,
  setupAccount,
}) => {
  const { reconciliationPaymentAccounts } = financialInstitutionBankAccount;
  const isMappedWithRPA: boolean = Boolean(reconciliationPaymentAccounts?.length);

  return employeeCardFeatureFlag ? (
    <Table.Cell className={ styles.cell }>
      {isMappedWithRPA ? (
        <div className={ styles['fic-banking-account-create-mapping'] }>
          {reconciliationPaymentAccounts?.map((rpaBankAccount) => (
            <div key={ rpaBankAccount.id } className={ styles['row-item'] }>
              <div>
                {rpaBankAccount.bankAccountName}
              </div>
              {rpaBankAccount.isMultiEntity && (
              <div className={ styles['multi-entity-maping-status'] }>
                {rpaBankAccount.isMapRequired ? (
                  <Button variant="outline">
                    Map Employee Cards
                  </Button>
                ) : (
                  <>
                    <span>{rpaBankAccount.mappingStatus}</span>
                    <Tooltip.Hover content="We are currently fetching the account owners based on the transactions. This might take hours to fetch." variant="dark">
                      <div className={ styles['multi-entity-maping-info-icon'] }>
                        <InfoIcon />
                      </div>
                    </Tooltip.Hover>
                  </>
                )}
              </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <>
          <span className={ styles['no-bank-account-mapped-for-employee-cards'] }>
            {`No ${accountType === 'credit_card' ? 'Credit Card' : 'Banking Account'} mapped`}
          </span>
          <ButtonLink
            bsStyle="link"
            className={ styles['map-credit-card'] }
            onClick={ setupAccount.open }
          >
            {`Map ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}`}
          </ButtonLink>
        </>
      )}
    </Table.Cell>
  ) : (
    <Table.Cell>
      {financialInstitutionBankAccount.bankAccountName ? (
        <span>{financialInstitutionBankAccount.bankAccountName}</span>
      ) : (
        <span className={ styles['no-bank-account-mapped'] }>No Banking Account mapped</span>
      )}
    </Table.Cell>
  );
};

export default FICBankAccountMappingInfo;
