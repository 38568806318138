import React from 'react';

import { useByReconciliationPaymentAccountId } from '@src/hooks/queries/payment_accounts';
import { useGetReconciliationPaymentAccountById } from '@src/hooks/queries/reconciliation_payment_accounts';
import useBusinessFeatureFlag from '@src/hooks/use_business_feature_flag';
import { TID } from '@src/types/common';

import { Header } from '@src/components/banking_accounts/accounts/employee_cards/header';
import EmployeeCards from '@src/components/banking_accounts/accounts/employee_cards/list';
import Spinner from '@src/components/ui/spinner';

import './employee_cards.scss';

interface EmployeeCardPageProps {
	businessId: TID,
	rcPaymentAccountId: TID,
}

// Component for displaying employee card details including header and list of cards
const EmployeeCard = ({
  businessId,
  rcPaymentAccountId,
}: EmployeeCardPageProps) => {
  const mappingBankAccountsReconciliationCenter = useBusinessFeatureFlag(
    window.Docyt.Common.Constants.MAPPING_BANK_ACCOUNTS_RECONCILIATION_CENTER,
    businessId,
  );

  // Fetching reconciliation payment account details using custom hook
  const reconciliationPaymentAccountQuery = useGetReconciliationPaymentAccountById({
    accountId: rcPaymentAccountId,
    businessId,
  });

  const employeeCardsQueryAdapter = useByReconciliationPaymentAccountId({
    businessId,
    noConsiderArchive:              true,
    reconciliationPaymentAccountId: reconciliationPaymentAccountQuery?.data?.reconciliationPaymentAccount.id,
  }, true);

  // If data is not yet available, render nothing
  if (!reconciliationPaymentAccountQuery.data) {
    return null;
  }

  // Render the employee card page layout with header and cards list
  return (
    <div className="page-main width-100-percent">
      {reconciliationPaymentAccountQuery.isLoading && (<Spinner />)}
      <Header
        businessId={ businessId }
        rcPaymentAccount={ reconciliationPaymentAccountQuery.data?.reconciliationPaymentAccount }
        refetchEmployeeCards={ employeeCardsQueryAdapter.refetch }
      />
      <div className="page-main-body">
        <EmployeeCards
          employeeCardsQueryAdapter={ employeeCardsQueryAdapter }
          mappingBankAccountsReconciliationCenter={ Boolean(mappingBankAccountsReconciliationCenter) }
          rcPaymentAccount={ reconciliationPaymentAccountQuery.data?.reconciliationPaymentAccount }
        />
      </div>
    </div>
  );
};

export default EmployeeCard;
