import React, { useMemo } from 'react';

import { useGetAllPortfolioUsers } from '@src/hooks/queries/users';
import { IBusinessUser } from '@src/types/business_users';
import { TID } from '@src/types/common';

import { UserAvatar } from '@src/components/ui/avatars';
import { ReactSelectInput } from '@src/components/ui/form';
import { SearchIcon } from '@src/components/utils/icomoon';

import UserItem from './user_item';

import styles from './styles.module.scss';

interface IEditPortfolioUsersSectionProps {
  portfolioId: TID,
  reportName?: string,
  users: IBusinessUser[],
  onRemove: (user: IBusinessUser) => void,
  onSelect: (user: IBusinessUser | null) => void,
}

const formatOptionLabel = (user: IBusinessUser): JSX.Element => {
  return (
    <div className="display-flex align-items-center pt-5 pb-5">
      <UserAvatar avatar={ user.avatar } mr={ 12 } size={ 24 } />
      <span className="m-r-12">{user.parsedFullname}</span>
      <span className="m-r-12">{user.email}</span>
      <span className="m-r-12">{user.phone}</span>
    </div>
  );
};

const getOptionLabel = (user: IBusinessUser) => `${user.parsedFullname} ${user.email} ${user.phone}`;
const getOptionValue = (user: IBusinessUser) => String(user.id);

const EditPortfolioUsersSection = ({
  portfolioId,
  reportName,
  onRemove,
  onSelect,
  users,
}: IEditPortfolioUsersSectionProps) => {
  const query = useGetAllPortfolioUsers({
    portfolioId,
  });

  const businessUsers = useMemo(() => {
    const userIds = users.map((u) => u.id);
    return (query.data?.users || []).filter((user) => !userIds.includes(user.id));
  }, [query?.data, users]);

  return (
    <div className="edit-users-modal-region width-100-percent">
      <div className="search-input-region display-flex align-items-center width-100-percent">
        <SearchIcon className="m-r-12" />
        <ReactSelectInput
          hideIndicators
          isSearchable
          className={ styles['select-user-input'] }
          formatOptionLabel={ formatOptionLabel }
          getOptionLabel={ getOptionLabel }
          getOptionValue={ getOptionValue }
          options={ businessUsers }
          placeholder="Type name or email to add user"
          value={ null }
          widthPercent={ 100 }
          onChange={ onSelect }
        />
      </div>
      <div className="user-list-region width-100-percent p-t-15 p-r-15 p-l-15 p-b-15">
        {
          reportName && (
            <p>
              The following users have access to
              {' '}
              <span className="font-bold">{reportName}</span>
              .
            </p>
          )
        }
        <div className="user-list width-100-percent m-t-5">
          {users.map((user) => (
            <UserItem key={ user.email } user={ user } onRemove={ onRemove } />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditPortfolioUsersSection;
