import React, { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { CountryOptions } from '@src/constants/country_options';
import { UsaStateOptions } from '@src/constants/usa_state_options';
import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import SelectForm from '@src/components/ui/form';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

import { IW9InfoFormValues, w9InfoValidation } from './schema';

import styles from './styles.module.scss';

interface IW9InfoDetailsFormProps {
  businessId: TID,
  id: TID,
  formId?: string,
  isEditing?: boolean,
  onSubmit: SubmitHandler<IW9InfoFormValues>,
  w9Info?: IW9InfoResponse,
  handleEditClose?: () => void;
}

const EntityOptions = [
  {
    value: 'Sole Proprietorship',
    name:  'Sole Proprietorship',
  },
  {
    value: 'Corporation',
    name:  'Corporation',
  },
  {
    value: 'LLC',
    name:  'LLC',
  },
  {
    value: 'Partnership',
    name:  'Partnership',
  },
];

const FormLabel = ({ text, isSecondary = false, showAsterisk = false, className = '' }:
  { text: string | React.ReactNode; isSecondary?: boolean;
    showAsterisk?: boolean; className?: string }) => (
      <p className={ `${isSecondary ? styles['form-label-secondary'] : styles['form-label']} ${className}` }>
        {text}
        {showAsterisk && <span className={ styles.asterisk }>*</span>}
      </p>
);

const W9InfoDetailsForm = ({
  formId,
  onSubmit,
  businessId,
  id,
  w9Info,
  isEditing,
  handleEditClose,
}: IW9InfoDetailsFormProps): JSX.Element => {
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IW9InfoFormValues>({
    defaultValues: {
      addressCountry: 'US',
      addressState:   w9Info?.w9Address?.addressState ?? 'AL',
      addressCity:    w9Info?.w9Address?.addressCity ?? '',
      addressLine1:   w9Info?.w9Address?.addressLine1 ?? '',
      addressZip:     w9Info?.w9Address?.addressZip ?? '',
      w9BusinessType: w9Info?.w9BusinessType ?? 'Sole Proprietorship',
      w9Ein:          w9Info?.w9Ein ?? '',
      w9Ssn:          w9Info?.w9Ssn ?? '',
      w9Email:        w9Info?.w9Email ?? '',
      w9LegalName:    w9Info?.w9LegalName ?? '',
      w9Phone:        w9Info?.w9Phone ?? '',
      w9SignedDate:   w9Info?.w9SignedDate ?? '',
      w9Name:         w9Info?.w9Name ?? '',
    },
    resolver: yupResolver(w9InfoValidation),
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedTin, setSelectedTin] = useState('ssn');
  const dateController = useController({ name: 'w9SignedDate', control });

  const legalBusinessName = watch('w9LegalName');
  const addressLine = watch('addressLine1');
  const addressCountry = watch('addressCountry');
  const addressState = watch('addressState');
  const addressCity = watch('addressCity');
  const addressZip = watch('addressZip');
  const w9Ssn = watch('w9Ssn');
  const w9Ein = watch('w9Ein');
  const w9Phone = watch('w9Phone');

  const tin = w9Ssn || w9Ein;

  const formValid = !!(
    legalBusinessName
    && addressLine
    && addressCity
    && addressState
    && addressCountry
    && addressZip
    && tin
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTin(event.target.value);
  };

  const formatPhoneNumber = (phone: string) => {
    if (!phone) return '';
    const phoneNumber = phone.replace(/[^0-9]/g, '');
    if (!phoneNumber.startsWith('1')) {
      return `+1${phoneNumber}`;
    }
    return `+${phoneNumber}`;
  };

  useEffect(() => {
    if (w9Phone) {
      const formattedPhone = formatPhoneNumber(w9Phone);
      setValue('w9Phone', formattedPhone, { shouldValidate: true });
    }
  }, [w9Phone, setValue]);

  useEffect(() => {
    if (selectedTin === 'ssn') {
      setValue('w9Ein', '');
    } else {
      setValue('w9Ssn', '');
    }
  }, [selectedTin, setValue]);

  useEffect(() => {
    setIsFormValid(formValid);
  }, [formValid, setIsFormValid]);

  useEffect(() => {
    if (w9Info?.w9Ssn) {
      setSelectedTin('ssn');
      setValue('w9Ssn', `*****${w9Info.w9Ssn}`);
    } else if (w9Info?.w9Ein) {
      setSelectedTin('ein');
      setValue('w9Ein', `*****${w9Info.w9Ein}`);
    } else {
      setSelectedTin('ssn');
    }
  }, [w9Info, setValue]);

  return (
    <Form className={ styles['form-container'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <input type="hidden" value={ businessId } { ...register('businessId') } />
      <input type="hidden" value={ id } { ...register('id') } />
      <div className={ styles['form-header'] }>
        <span className={ styles['form-title'] }>Add W9 Info</span>
        {isEditing && (
        <a
          className={ styles['info-edit'] }
          role="button"
          tabIndex={ 0 }
          onClick={ handleEditClose }
        >
          Close
        </a>
        )}
      </div>
      <div className={ styles['form-content-container'] }>
        <FormLabel
          showAsterisk
          className={ styles['required-label'] }
          text="Required fields"
        />
        <div className={ styles['form-content'] }>
          <FormLabel
            isSecondary
            showAsterisk
            text="Legal Name (As shown
            on the income tax return)"
          />
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="Legal Name"
              placeholder="Enter here"
              { ...register('w9Name') }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            showAsterisk
            text="Legal Business Name"
          />
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="Legal Business Name"
              placeholder="Enter here"
              { ...register('w9LegalName') }
              error={ errors.w9LegalName?.message }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            text="Entity Type"
          />
          <div className={ styles['form-field'] }>
            <SelectForm.SelectField
              hideLabel
              className={ styles['select-field'] }
              defaultValue="Sole Proprietorship"
              options={ EntityOptions }
              { ...register('w9BusinessType') }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            showAsterisk
            text="Legal Entity Address"
          />
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="Legal Entity Address"
              placeholder="Street"
              { ...register('addressLine1') }
              error={ errors.addressLine1?.message }
            />
          </div>
        </div>
        <div className={ styles['address-row-container'] }>
          <div className={ styles['address-row-column'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="City"
              placeholder="City"
              { ...register('addressCity') }
              error={ errors.addressCity?.message }
            />
          </div>
          <div className={ styles['address-row-column'] }>
            <SelectForm.SelectField
              hideLabel
              className={ styles['select-field'] }
              defaultValue="AL"
              options={ UsaStateOptions }
              { ...register('addressState') }
              error={ errors.addressState?.message }
            />
          </div>
        </div>
        <div className={ styles['address-row-container'] }>
          <div className={ styles['address-row-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              inputMode="numeric"
              label="Zip"
              pattern="[0-9]*"
              placeholder="ZIP Code"
              onInput={ (e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              } }
              { ...register('addressZip') }
              error={ errors.addressZip?.message }
            />
          </div>
        </div>
        <div className={ styles['address-row-container'] }>
          <div className={ styles['address-row-field'] }>
            <SelectForm.SelectField
              disabled
              hideLabel
              className={ styles['select-field'] }
              defaultValue="US"
              options={ CountryOptions }
              { ...register('addressCountry') }
              error={ errors.addressCountry?.message }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            showAsterisk
            text="Taxpayer Identification
            Number (TIN)"
          />
          <div className={ styles['form-field'] }>
            <div className={ styles['toggle-option'] }>
              <input
                defaultChecked
                checked={ selectedTin === 'ssn' }
                className={ styles['toggle-option-input'] }
                id="ssn"
                name="tin"
                type="radio"
                value="ssn"
                onChange={ handleRadioChange }
              />
              <label className={ styles['toggle-option-label'] } htmlFor="ssn">
                <span>Business Owner’s SSN</span>
              </label>
            </div>
            <div className={ styles['toggle-option'] }>
              <input
                checked={ selectedTin === 'ein' }
                className={ styles['toggle-option-input'] }
                id="ein"
                name="tin"
                type="radio"
                value="ein"
                onChange={ handleRadioChange }
              />
              <label className={ styles['toggle-option-label'] } htmlFor="ein">
                <span>Business EIN</span>
              </label>
            </div>
          </div>
        </div>
        <div className={ styles['address-row-container'] }>
          <div className={ styles['address-row-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              inputMode="numeric"
              label="Taxpayer Identification Number (TIN)"
              placeholder={ selectedTin === 'ssn' ? 'Enter Business Owner’s SSN' : 'Enter 9 Digit EIN' }
              value={ selectedTin === 'ssn' ? w9Ssn : w9Ein }
              { ...register(selectedTin === 'ssn' ? 'w9Ssn' : 'w9Ein') }
              error={ errors[selectedTin === 'ssn' ? 'w9Ssn' : 'w9Ein']?.message }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            text="Email"
          />
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="Email"
              placeholder="Enter email id"
              { ...register('w9Email') }
              error={ errors.w9Email?.message }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            text="Phone"
          />
          <div className={ styles['form-field'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-field-border'] }
              label="Phone"
              placeholder="Enter here"
              onInput={ (e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              } }
              { ...register('w9Phone') }
              error={ errors.w9Phone?.message }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <FormLabel
            text="W9 Signed Date
            (Optional)"
          />
          <div className={ styles['form-field'] }>
            <Form.DateField
              hiddenLabel
              className={ styles['form-field-border'] }
              label="W9 Signed Date (Optional)"
              placeholder="MM/DD/YYYY"
              { ...dateController.field }
              error={ errors.w9SignedDate?.message }
            />
          </div>
        </div>
      </div>
      <div className={ styles['form-button'] }>
        <Button
          className={ styles.button }
          disabled={ !isFormValid }
          form={ formId }
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export {
  IW9InfoDetailsFormProps,
  IW9InfoFormValues,
  W9InfoDetailsForm as default,
};
