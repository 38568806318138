/* eslint-disable import/order */
import React, { useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';

import MultiColumnAddField from '@src/components/business_reports/common/multi_column_add_field';
import Filter from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import ReportRefreshStatus from './report_refresh_status';
import { reportDataPeriodType } from '../../atoms';
import { IReportDatasCollection, reportFilterConfiguration } from '../hooks';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetReportServiceAccess } from '@src/hooks/queries/report_service/custom_reports';

import styles from '../styles.module.scss';

const ReportDataTableFilter = ({
  configurations,
  report,
  collection,
  isShownCustomize,
  setUpdate,
  update
}: {
  configurations: IReportConfiguration[];
  report: IReport;
  collection: IReportDatasCollection;
  isShownCustomize: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  update: boolean;
}) => {
  const [value, setValue] = useRecoilState(hideZeroRowsType);
  const periodType = useRecoilValue(reportDataPeriodType);
  const reportFilters = reportFilterConfiguration(configurations);
  const business = useBusinessContext();
  const reportServiceAccess = useGetReportServiceAccess(business.id);
  const subscriptionBasedAccess = useMemo(() => reportServiceAccess.data || {}, [reportServiceAccess]);
  const filtersAccess = subscriptionBasedAccess.filtersAccess;
  const subscriptionBasedReportfilter = reportFilters.filter(item => filtersAccess?.includes(item.id));
  
  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div className={ styles['filter-area'] }>
        <Filter.TextNormalFiled
          className={ styles['search-width'] }
          label="Search by Name"
          placeholder="Search by Name"
        />
        <Filter.DateField
          hideClear
          className={ styles['filter-width'] }
          minDate={ business.bookkeepingStartDate }
          name="reportDataRange"
          periodType={ periodType }
        />

        {isShownCustomize && (
          <MultiColumnAddField
            collection={ collection }
            kind="reportDetail"
            placeholder="Customize"
            report={ report }
            reportFilters={ subscriptionBasedReportfilter }
          />
        )}

        <CheckboxInput
          checked={ value }
          title="Hide empty rows"
          onChange={ (checked: boolean) => setValue(checked) }
        />
      </div>
      <ReportRefreshStatus
        collection={ collection }
        report={ report }
        setUpdate={ setUpdate }
        update={ update }
      />
    </Filter.TableContainer>
  );
};

export default React.memo(ReportDataTableFilter);
