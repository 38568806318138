import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { industryOptions } from '@src/constants/industry_options';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreatePortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';

import Modal from '@src/components/ui/modal';
import { SelectInput, TextInput, TOption } from '@src/components/ui_v2/inputs';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';

interface ICreatePortfolioProps extends IUseModalProps {
  businesses: IBusiness[] | IClientBusiness[];
  onCreate: () => void;
}

const CreatePortfolio = ({
  isOpen,
  onCancel,
  onCreate,
  onDone,
  businesses,
}: ICreatePortfolioProps): JSX.Element => {
  const createPortfolio = useCreatePortfolio();
  const [portfolioName, setPortfolioName] = useState<string>('');
  const [selectedIndustry, setSelectedIndustry] = useState<TOption | null>(null);
  const [selectedIndustryBusiness, setSelectedIndustryBusiness] = useState<TOption[]>([]);
  const [industryBusinessData, setIndustryBusinessData] = useState<TOption[]>([]);

  const handleOnChangeIndustry = (options: TOption | null) => {
    setSelectedIndustry(options);
    const indusBusiness = businesses.filter((business) => {
      return options?.value === `${business.standardCategoryId}`;
    });
    const tempBusiness = indusBusiness.map((item) => { return { label: item.displayName, value: `${item.id}` }; });
    setIndustryBusinessData(tempBusiness);
    setSelectedIndustryBusiness([]);
  };

  const resetFields = () => {
    setSelectedIndustry(null);
    setPortfolioName('');
    setIndustryBusinessData([]);
    setSelectedIndustryBusiness([]);
  };

  const handleCancel = useCallback(() => {
    resetFields();
    onCancel();
  }, [onCancel]);

  const isDisabled = portfolioName.trim() === ''
    || !selectedIndustry
    || selectedIndustryBusiness.length === 0;

  const handleProceed = () => {
    if (isDisabled) return;

    const reportSetting = {
      portfolio: {
        name:               portfolioName,
        standardCategoryId: Number(selectedIndustry.value),
        businessIds:        selectedIndustryBusiness.map((item) => Number(item.value)),
      },
    };
    createPortfolio.mutate(reportSetting, {
      onSuccess: () => {
        resetFields();
        onCreate();
        toastr.success('Portfolio Created Successfully', 'Success!');
        onDone();
      },
      onError: (err) => {
        toastr.error(err.message, 'Error!');
        onDone();
      },
    });
  };

  return (
    <Modal.Standard
      isProceedDisabled={ isDisabled }
      proceedTitle="Add"
      show={ isOpen }
      title="Add New Portfolio"
      onCancel={ handleCancel }
      onProceed={ handleProceed }
    >
      {() => (
        <div className="download-report-setting">
          <div className="inputRow m-b-15">
            <span className="inputTitle">Portfolio Name:</span>
            <div className="export-input">
              <TextInput
                placeholder="Enter name"
                value={ portfolioName }
                onChange={ (e) => setPortfolioName(e.target.value) }
              />
            </div>
          </div>

          <div className="inputRow m-b-15">
            <span className="inputTitle">Select Industry:</span>
            <SelectInput
              className="export-input"
              options={ industryOptions }
              placeholder="Select Industry"
              value={ selectedIndustry }
              onChange={ handleOnChangeIndustry }
            />
          </div>

          <div className="inputRow m-b-15">
            <span className="inputTitle">Select Business(s):</span>
            <div className="export-input">
              <MultiSelectAddItem
                addItem
                hideSelectAllOption
                menuClassName="dropdown-menu-fix-height"
                options={ industryBusinessData }
                placeholder="Select Business"
                value={ selectedIndustryBusiness }
                onChange={ setSelectedIndustryBusiness }
              />
            </div>
          </div>
        </div>
      )}
    </Modal.Standard>
  );
};

const useCreatePortfolioModal = makeUseModal(
  CreatePortfolio,
);

export {
  CreatePortfolio as default, ICreatePortfolioProps, useCreatePortfolioModal,
};
