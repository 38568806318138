import React from 'react';

import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import Table from '@src/components/ui_v2/table';

interface IAccruedProps {
  adjustmentEntries: IAdjustmentEntry[];
}

const Accrued = ({ adjustmentEntries }: IAccruedProps) => {
  return (
    <Table className="related-journal-entries-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell>Date</Table.HCell>
          <Table.HCell>Chart of Account</Table.HCell>
          <Table.AmountHCell widthPercent={ 15 }>Amount</Table.AmountHCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {adjustmentEntries.map((entry) => (
          <Table.Row key={ entry.id }>
            <Table.DateCell date={ entry.entryDate } />
            <Table.Cell>
              {entry.lines && entry.lines.length > 1
                ? `${entry.chartOfAccount.name} + ${entry.lines.length - 1} More`
                : entry.chartOfAccount.name}
            </Table.Cell>
            <Table.AmountCell
              pointer
              amount={ entry.amount }
              className="in-blue-900"
              onClick={ () => entry.qboLink?.qboLink && window.open(entry.qboLink.qboLink, '_blank') }
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(Accrued);
