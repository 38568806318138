import React, { useMemo } from 'react';

import uniq from 'lodash/uniq';

import { IBusinessSimple } from '@src/types/businesses';
import { IPortfolioDashboard } from '@src/types/portfolio';

import UnavailableView from '@src/components/business_dashboards/dashboard/dashboard_unavailable_view';

import Container from './details_container';

interface IDashboardDetailsContainerWrapperProps {
  dashboard: IPortfolioDashboard,
  businesses: IBusinessSimple[],
  widgetId?: string,
}

const DashboardDetailsContainerWrapper = ({
  dashboard,
  businesses,
  widgetId,
}: IDashboardDetailsContainerWrapperProps): JSX.Element => {
  const isAvailable = useMemo(() => {
    const widgetSTCIds = dashboard.widgetTemplates
      .find((template) => template.id === widgetId)?.standardCategoryIds || [];

    const businessesSTCIds = uniq(businesses.map((b) => b.standardCategoryId));
    if (businessesSTCIds.length === 0) return true;
    if (businessesSTCIds.length !== 1 || businessesSTCIds[0] === null) return false;

    if (widgetSTCIds.length === 0) return true;

    return businessesSTCIds.every((id) => widgetSTCIds.includes(id));
  }, [dashboard, businesses, widgetId]);

  return (
    isAvailable
      ? (
        <Container
          businesses={ businesses }
          dashboard={ dashboard }
          widgetId={ widgetId }
        />
      )
      : (
        <UnavailableView />
      )
  );
};

export default DashboardDetailsContainerWrapper;
