import React, { useEffect, useMemo, useState } from 'react';

import { DetailsRegionContext, IDetailsRegionContextProps } from './hooks';

interface IDetailsRegionProviderProps {
  children: React.ReactNode,
}

const DetailsRegionProvider = ({ children }: IDetailsRegionProviderProps) => {
  const [secondHeaderHeight, setSecondHeaderHeight] = useState<number>(0);
  const [headerRegionHeight, setHeaderRegionHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  useEffect(() => {
    const serviceFullContainer = window.document.getElementsByClassName('service-full-container')[0] as HTMLElement;

    if (serviceFullContainer) {
      serviceFullContainer.style.height = '100%';
    }

    setSecondHeaderHeight(Number(window.document?.getElementById('second-header-region')?.clientHeight));

  }, [setSecondHeaderHeight]);

  const contextValue: IDetailsRegionContextProps = useMemo(
    () => ({
      headerRegionHeight,
      footerHeight,
      secondHeaderHeight,
      bannerHeight,
      setHeaderRegionHeight,
      setFooterHeight,
      setBannerHeight,
    }),
    [
      headerRegionHeight,
      footerHeight,
      bannerHeight,
      secondHeaderHeight,
      setHeaderRegionHeight,
      setFooterHeight,
      setBannerHeight,
    ]
  );

  return (
    <DetailsRegionContext.Provider value={ contextValue }>
      { children }
    </DetailsRegionContext.Provider>
  );
};

export default React.memo(DetailsRegionProvider);
