import React from 'react';

import { rcBankAccountsPath } from '@src/routes';
import { TID } from '@src/types/common';

import Alert from '@src/components/ui_v2/alerts/alert'; // Adjust the path as necessary
import { LaunchIcon } from '@src/components/utils/icomoon'; // Import the icon component

import styles from './styles.module.scss';

interface UnmappedAccountWarningProps {
  onTryAgain: () => void;
  businessId: TID;
  loading: boolean;
}

const UnmappedAccountWarning: React.FC<UnmappedAccountWarningProps> = ({ onTryAgain, businessId, loading }) => {
  const openInNewTab = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.open(rcBankAccountsPath(businessId as TID), '_blank');
  };

  return (
    <Alert className={ styles.warning_container } variant="danger">
      <h2>Chart of account not mapped</h2>
      <p>Map a chart of accounts to the selected payment method and try again.</p>
      <div className={ styles.buttons }>
        <a
          className={ styles['map-button'] }
          rel="noreferrer"
          role="button"
          tabIndex={ 0 }
          target="_blank"
          onClick={ openInNewTab }
        >
          Map Chart of Account
          <LaunchIcon fontSize={ 16 } style={ { marginLeft: '8px' } } />
        </a>
        <button className={ styles['try-again'] } disabled={ loading } type="button" onClick={ onTryAgain }>
          { loading ? 'Loading...' : 'Try Again' }
        </button>
      </div>
    </Alert>
  );
};

export default UnmappedAccountWarning;
