import React, { useMemo } from 'react';

import { useGetAllPortfolioUsers } from '@src/hooks/queries/users';
import { IBusinessUser } from '@src/types/business_users';
import { IPortfolioDashboard } from '@src/types/portfolio';

import { useEditPortfolioUsersModal } from '@src/components/common/edit_portfolio_users_modal/edit_portfolio_users_modal';
import { Button } from '@src/components/ui_v2/buttons';

interface IPortfolioDashboardShareActionProps {
  currentDashboard: IPortfolioDashboard,
  title?: string,
  prefixIcon?: React.ReactNode,
  onDone: (accessibleUsers: IBusinessUser[]) => void,
}

const PortfolioDashboardShareAction = ({
  currentDashboard,
  prefixIcon,
  title = 'Share Dashboard',
  onDone,
}: IPortfolioDashboardShareActionProps): JSX.Element => {
  const shareDashboard = useEditPortfolioUsersModal();

  const query = useGetAllPortfolioUsers({
    portfolioId: currentDashboard.portfolioId
  });

  const users = useMemo(() => {
    return (query.data?.users || [])
      .filter((user) => currentDashboard.dashboardUserIds.includes(user.id));
  }, [query.data?.users, currentDashboard.dashboardUserIds]);

  return (
    <>
      <Button
        prefixIcon={ prefixIcon }
        variant="primary"
        onClick={ shareDashboard.open }
      >
        { title }
      </Button>
      <shareDashboard.Component
        { ...shareDashboard.props }
        editUsers={ users }
        portfolioId={ currentDashboard.portfolioId }
        title="Share Dashboard"
        onDone={ onDone }
      />
    </>
  );
};

export default PortfolioDashboardShareAction;
