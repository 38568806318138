import React, { useCallback } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { Button } from '@src/components/ui/buttons';

import BusinessDetails from './business_details';

import styles from './styles.module.scss';

interface IRequestDocumentViewProps {
  invitation: ISelfOnboardingInvitation,
}

const RequestDocumentView = ({
  invitation,
}: IRequestDocumentViewProps) => {
  const handleSubmit = useCallback(() => {
    if (invitation.onboardingInformation.needsBillingSetting) {
      Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_BILLING_INFO}`, { trigger: true });
    } else {
      Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_CONNECT_QBO}`, { trigger: true });
    }
  }, [invitation]);

  return (
    <div className={ styles['self-onboarding-request-document-container'] }>
      <div className={ styles['tasks-container'] }>
        <h2>
          Hey
          { ' ' }
          { window.Docyt.currentAdvisor.get('first_name') }
          { ', ' }
          help us with the below onboarding details!
        </h2>
        <p className="font-12 in-grey-1050 m-b-20">
          The information you provide will be handled with the utmost confidentiality and
          { ' ' }
          used exclusively for the purpose of enhancing your experience with Docyt.
        </p>
        <BusinessDetails
          invitation={ invitation }
        />
      </div>
      <div className="setup-client-footer">
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default RequestDocumentView;
