import React from 'react';

import Table from '@src/components/ui_v2/table';

import { TColumn, TModel } from './types';

interface ICollectionTableLoadingCellProps<Model extends TModel, TSortColumn extends string = never> {
  column: TColumn<Model, TSortColumn>,
}

const CollectionTableLoadingCell = <Model extends TModel, TSortColumn extends string = never>({
  column,
}: ICollectionTableLoadingCellProps<Model, TSortColumn>) => {
  if (column.hidden) return null;

  if (column.icon) {
    return <Table.AvatarLoadingCell />;
  }

  if (column.type === 'icon') {
    return <Table.IconLoadingCell />;
  }

  return <Table.NormalLoadingCell />;
};

export default React.memo(CollectionTableLoadingCell) as typeof CollectionTableLoadingCell;
