import React from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import Cell, { ITableCellProps } from '../cell';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './styles.module.scss';

interface IIconLoadingCellProps extends Omit<ITableCellProps, 'children'> {}

const IconLoadingCell = ({
  className,
  ...cellProps
}: IIconLoadingCellProps) => {
  const classes = classNames(styles['icon-skeleton'], className);
  
  return (
    <Cell { ...cellProps } className={ classes }>
      <Skeleton className={ styles.size } />
    </Cell>
  );
};

export default IconLoadingCell;

