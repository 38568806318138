import React, { useEffect } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import { IFinancialInsightsInput } from '@src/components/settings/global/notifications/financial_insights_email/edit_modal/schema';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Section from '@src/components/utils_v2/section';

import { useManagementGroupSettingsCollection } from './hooks';
import Table from './management_group_settings_table';

interface IManagementGroupSettingsProps
{
  field: ControllerRenderProps<IFinancialInsightsInput, 'selectedGroups'>
}

const ManagementGroupSettings = ({
  field: { onChange },
}: IManagementGroupSettingsProps) => {
  const collection = useManagementGroupSettingsCollection();

  useEffect(() => {
    onChange(collection.selectedRecords);
  }, [collection.selectedRecords, onChange]);

  return (
    <DetailsRegion>
      <Section.Provider section={ collection.section }>
        <Table
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </Section.Provider>
    </DetailsRegion>
  );
};

export default React.memo(ManagementGroupSettings);
