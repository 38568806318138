import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getPortfolioDashboardById } from '@src/requests/portfolio_service/portfolio';
import { IGetPortfolioDashboardParams, IGetPortfolioDashboardResponse } from '@src/types/portfolio';

const useGetPortfolioDashboard = (params: IGetPortfolioDashboardParams) => {
  return useQuery<IGetPortfolioDashboardResponse, Error>(
    [QueryKey.portfolioDashboard, params],
    () => getPortfolioDashboardById(params),
    {
      enabled: !!params.id
    },
  );
};

export {
  useGetPortfolioDashboard,
};
