import React, { useMemo } from 'react';

import { TDate } from '@src/types/common';

import Dropdown from '@src/components/ui_v2/dropdown';

import MonthPeriod from './date_range_month_period';
import Period from './date_range_period';
import { TPeriod } from './types';
import { buildMonthlyPeriodsForMonthlyRefresh, buildMonthPeriods, buildPeriods } from './utils';

import styles from './styles.module.scss';

interface IDateRangePeriodSelectorProps {
  today?: Date,
  hideAllTime?: boolean,
  hideCalenderMonths?: boolean,
  minDate?: TDate | undefined,
  monthlyPeriods?: boolean,
  onPeriodSelected: (period: TPeriod) => void,
  customOptions?: () => JSX.Element
}

const DateRangePeriodSelector = ({
  hideAllTime = false,
  hideCalenderMonths = false,
  monthlyPeriods = false,
  today = new Date(),
  minDate,
  onPeriodSelected,
  customOptions,
}: IDateRangePeriodSelectorProps): JSX.Element => {
  const periods: TPeriod[] = useMemo(() => {
    const periodData = monthlyPeriods ? buildMonthlyPeriodsForMonthlyRefresh(today) : buildPeriods(today, minDate);

    if (hideAllTime) {
      return periodData.filter((f: TPeriod) => f.label !== 'All time');
    }

    return periodData;
  }, [today]);

  const monthPeriods: TPeriod[] = useMemo(() => {
    return buildMonthPeriods(today);
  }, [today]);

  return (
    <div className={ styles['date-range-periods-selector'] } role="menu">
      {!hideCalenderMonths && (
        <Dropdown.Submenu
          className={ styles['date-range-month-selector'] }
          itemClassName={ styles['date-range-period'] }
          title="Calendar Month"
        >
          {
          monthPeriods.map((period) => (
            <MonthPeriod
              key={ period.label }
              period={ period }
              onSelected={ onPeriodSelected }
            />
          ))
        }
        </Dropdown.Submenu>
      )}
      {
        periods.map((period) => (
          <Period
            key={ period.label }
            period={ period }
            onSelected={ onPeriodSelected }
          />
        ))
      }
      {customOptions && customOptions()}
    </div>
  );
};

export default DateRangePeriodSelector;
