import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Section from '@src/components/utils_v2/section';

import { useSelectMergeableVendorCollection } from './hooks';
import Table from './options/address_option';

interface ISelectMergeableVendorProps
{
  businessVendor: IBusinessVendor,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>,
}

const SelectMergeableVendor = ({
  businessVendor,
  setVendorData,
}: ISelectMergeableVendorProps) => {
  const collection = useSelectMergeableVendorCollection({ businessVendor });

  return (
    <Section.Provider section={ collection.section }>
      <DetailsRegion>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          setVendorData={ setVendorData }
          sorting={ collection.sorting }
          vendor={ businessVendor }
        />
      </DetailsRegion>
    </Section.Provider>
  );
};

export default React.memo(SelectMergeableVendor);
