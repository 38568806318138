import React, { useCallback, useMemo } from 'react';

import { useAccountAllTypeCategories } from '@src/hooks/queries/account_types';
import { IAccountSubType } from '@src/types/account_types';
import { IChartOfAccount } from '@src/types/chart_of_accounts';

import IconSelectInput, { TIconOption }
  from '@src/components/ui/form/inputs/react_select/icon_select_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IAccountTypeCategoryFieldProps {
  subType?: IAccountSubType['subtypeCode']
  value?: string,
  onChange: (value?: IChartOfAccount['id']) => void,
  error?: string,
  businessId?: number
}

interface TChartOfAccountOption extends TIconOption {
  parentId?: string
}

const chartOfAccountOption = (account: IChartOfAccount): TChartOfAccountOption => {
  let icon;
  let label;

  if (account.qboId === '0' || account.qboStatus === 'invalid') {
    icon = <WarningIcon className="in-red-400" />;
  } else if (account.qboId !== null || account.qboStatus === 'synced') {
    icon = <QBOOnIcon />;
  } else {
    icon = <QBOOffIcon />;
  }

  if (account.parentId) {
    label = `${account.accType}: ${account.parentName} ► ${account.name}`;
  } else {
    label = `${account.accType}: ${account.name}`;
  }

  if (account.number) {
    label = `${account.number}: ${label}`;
  }

  return { icon, label, value: String(account.id), parentId: String(account.parentId) };
};

const ParentCode = ({
  value,
  onChange,
  error,
  subType,
  businessId,
}: IAccountTypeCategoryFieldProps): JSX.Element => {
  const query = useAccountAllTypeCategories({ subtypeCode: subType });
  const options = useMemo(() => {
    if (!query.data?.chartOfAccounts) return [];

    const arr = query.data.chartOfAccounts;
    // if (businessId) {
    //   arr = query.data.chartOfAccounts.filter((i) => i.businessId === businessId);
    // } else {
    //   const uniqueByName = (array: IChartOfAccount[]) => {
    //     const seen = new Set();
    //     return array.filter((item) => !seen.has(item.name) && seen.add(item.name));
    //   };
    //
    //   arr = uniqueByName(arr);
    // }
    return arr.map(chartOfAccountOption);
  }, [query.data, businessId]);

  const selectedOption = useMemo(() => {
    if (!value) return null;

    return options.find((option) => option.parentId === String(value));
  }, [value, options]);

  const handleChange = useCallback((option: TIconOption | null) => {
    onChange(option ? Number(option.value) : undefined);
  }, [onChange]);

  return (
    <Wrapper error={ error } label="Parent Category">
      <IconSelectInput
        isSearchable
        options={ options }
        placeholder="Select Parent Category"
        value={ selectedOption }
        widthPercent={ 100 }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default ParentCode;
