import React, { useState, useCallback } from 'react';

import moment from 'moment';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useExportReportAsExcel } from '@src/hooks/queries/report_service/custom_reports';

import { IExportReportConfiguration } from '@src/requests/report_service/custom_reports';
import { API_DATE_FORMAT, apiMonthToDate, monthToApiEndDate, monthToApiStartDate } from '@src/utils/date_helpers';

import { MonthPickerInput } from '@src/components/ui/form';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDownloadConsolidatedReportProps extends IUseModalProps {
  reportServiceId: number,
  standardCategory: string,
  onCreateExportData: (result: boolean) => void,
  reportConfigData?: IExportReportConfiguration
}

const DownloadConsolidatedReport = ({
  reportServiceId,
  standardCategory,
  isOpen,
  onCancel,
  onDone,
  onCreateExportData,
  reportConfigData,
}: IDownloadConsolidatedReportProps): JSX.Element => {
  const currentDate = new Date();
  const [startDateStr, setStartDateStr] = useState<string>('');
  const [endDateStr, setEndDateStr] = useState<string>('');
  const downloadReports = useExportReportAsExcel();
  const { mutate } = downloadReports;

  const onStartDateChange = useCallback(
    (value) => {
      if (!value) return;

      const startDate = monthToApiStartDate(value);
      setStartDateStr(startDate);

      if (endDateStr && moment(startDate).isAfter(moment(endDateStr))) {
        setEndDateStr(startDate);
      }
    },
    [setStartDateStr, endDateStr, setEndDateStr],
  );

  const onEndDateChange = useCallback(
    (value) => {
      if (!value) return;

      const endDate = monthToApiEndDate(value);
      setEndDateStr(endDate);

      if (startDateStr && moment(endDate).isBefore(moment(startDateStr))) {
        setStartDateStr(endDate);
      }
    },
    [setEndDateStr, startDateStr, setStartDateStr],
  );

  const handleProceed = useCallback(() => {
    if (!startDateStr || !endDateStr) return;

    const startDate = apiMonthToDate(startDateStr) || startDateStr;
    const endDate = moment(endDateStr).endOf('month').format(API_DATE_FORMAT);
    const reportExportSetting = { 
      reportServiceId,
      startDate,
      endDate,
      exportType: 'consolidated_report',
      reportIds: reportConfigData?.filterConfig?.reports || [],
      columns: reportConfigData?.filterConfig?.columns || [],
      includeDrillDown: !!reportConfigData?.filterConfig?.includeDrillDown
    }
    mutate(
      reportExportSetting,
      { onSuccess: () => onCreateExportData(true) },
    );
    setStartDateStr('');
    setEndDateStr('');
    onDone();
  }, [startDateStr, endDateStr, mutate, reportServiceId, onDone, onCreateExportData, reportConfigData]);

  const handleCancel = useCallback(() => {
    setStartDateStr('');
    setEndDateStr('');
    onCancel();
  }, [onCancel]);

  return (
    <>
      <Modal.Standard
        isProceedDisabled={ !startDateStr && !endDateStr }
        proceedTitle="Download"
        show={ isOpen }
        title="Download Consolidated Report"
        onCancel={ handleCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="report-type-container display-flex-column m-t-48 m-b-8">
            <div className="display-flex align-items-center p-l-50 p-r-50">
              <span className="font-16 m-r-20 width-100-percent text-align-right">Select Period:</span>
              <MonthPickerInput
                options={ { endDate: currentDate, format: 'M yyyy' } }
                placeholder="Start Date"
                value={ startDateStr }
                onChange={ onStartDateChange }
              />
              <span className="m-r-10" />
              <MonthPickerInput
                options={ { endDate: currentDate, format: 'M yyyy' } }
                placeholder="End Date"
                value={ endDateStr }
                onChange={ onEndDateChange }
              />
            </div>
            <div className="display-flex align-items-center p-l-50 p-r-50 m-t-25">
              <span className="font-16 m-r-20 text-align-right width-40-percent">Industry:</span>
              <span className="font-bold width-100-percent">{standardCategory}</span>
            </div>
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ downloadReports } />
    </>
  );
};

const useDownloadConsolidatedReportModal = makeUseModal(DownloadConsolidatedReport);

export {
  IDownloadConsolidatedReportProps,
  useDownloadConsolidatedReportModal,
  DownloadConsolidatedReport as default,
};
