import React, { useEffect } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import { IBusinessVendor } from '@src/types/business_vendors';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Section from '@src/components/utils_v2/section';

import { ISelectMergeableVendorFormValues } from '../schema';
import { useSelectMergeableVendorCollection } from './hooks';
import Table from './select_mergeable_vendor_table';

interface ISelectMergeableVendorProps
{
  businessVendor: IBusinessVendor,
  field: ControllerRenderProps<ISelectMergeableVendorFormValues, 'mergeableVendor'>
}

const SelectMergeableVendor = ({
  businessVendor,
  field: { onChange },
}: ISelectMergeableVendorProps) => {
  const collection = useSelectMergeableVendorCollection({ businessVendor });

  useEffect(() => {
    onChange(collection.selectedRecord);
  }, [collection.selectedRecord, onChange]);

  return (
    <DetailsRegion>
      <div className="p-l-8 m-b-8">
        Select a vendor to merge with above vendor
      </div>
      <Section.Provider section={ collection.section }>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </Section.Provider>
    </DetailsRegion>
  );
};

export default React.memo(SelectMergeableVendor);
