module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1;
    
      __out.push('<ul class="nav nav-sidebar">\n  ');
    
      if ((ref = this.services_info) != null ? ref.is_business_admin : void 0) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.MANAGEMENT_GROUP_STEP ? 'active' : ''));
        __out.push('"\n      data-step="');
        __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.MANAGEMENT_GROUP_STEP));
        __out.push('"\n      data-global-feature-toggle="management-group-enabled">\n    <a>\n      <span>\n        <span class="icon-management-group font-26">\n          <span class="path1"></span><span class="path2"></span>\n        </span>\n        <span class="nav-item-text">Management Group</span>\n      </span>\n      ');
        if (this.management_group_id) {
          __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.QUICKBOOKS_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.QUICKBOOKS_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-qbo-on"></span>\n        <span class="nav-item-text">Accounting Software</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.QUICKBOOKS_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_PROFILE_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_PROFILE_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-home-business font-26">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span>\n        </span>\n        <span class="nav-item-text">Business Profile</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_PROFILE_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_TEAM_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_TEAM_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span><img class="img-business" src="');
    
      __out.push(__sanitize(configData.icons.SHOP));
    
      __out.push('" style="width:24px"></span>\n        <span class="nav-item-text">Business Employees</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_TEAM_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n   ');
    
      if ((ref1 = this.services_info) != null ? ref1.is_banking_service_admin_user : void 0) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BANKING_ACCOUNTS_STEP ? 'active' : ''));
        __out.push('" data-step="');
        __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BANKING_ACCOUNTS_STEP));
        __out.push('">\n    <a>\n      <span>\n        <span class="icon-bank-transactions-src">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>\n        </span>\n        <span class="nav-item-text">Financial Accounts</span>\n      </span>\n      ');
        if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BANKING_ACCOUNTS_STEP)) {
          __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.REVENUE_SETUP_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.REVENUE_SETUP_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-setup-settings font-20"></span>\n        <span class="nav-item-text">Revenue Setup</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.REVENUE_SETUP_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.PAYMENT_METHODS_STEP ? 'active' : ''));
    
      __out.push(' ');
    
      if (!(this.has_qbo_connection_history && this.industry_frozen)) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.PAYMENT_METHODS_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-bank-transactions">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n        </span>\n        <span class="nav-item-text">Payment Methods</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.PAYMENT_METHODS_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  ');
    
      if (this.for_client) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.ACCOUNTING_TEAM_STEP ? 'active' : ''));
        __out.push('" data-step="');
        __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.ACCOUNTING_TEAM_STEP));
        __out.push('">\n    <a>\n      <span>\n        <span class="icon-users">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n        </span>\n        <span class="nav-item-text">Accounting Team</span>\n      </span>\n      ');
        if (this.setup_for_step(6)) {
          __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.for_client || this.has_accounting_firm) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.SUBSCRIPTION_PLAN_STEP ? 'active' : ''));
        __out.push('" data-step="');
        __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.SUBSCRIPTION_PLAN_STEP));
        __out.push('">\n    <a>\n      <span>\n        <span class="icon-subscription-calendar"></span>\n        <span class="nav-item-text">Subscription Plans</span>\n      </span>\n      ');
        if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.SUBSCRIPTION_PLAN_STEP)) {
          __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BILLING_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BILLING_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-credit-card-account">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n        </span>\n        <span class="nav-item-text">Billing Information</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BILLING_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.DOCUMENT_COLLECTION_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.DOCUMENT_COLLECTION_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-document-collection font-20">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>\n        </span>\n        <span class="nav-item-text">Document Collection</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.DOCUMENT_COLLECTION_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.NOTIFICACTION_SETTINGS_STEP ? 'active' : ''));
    
      __out.push('" data-step="');
    
      __out.push(__sanitize(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.NOTIFICACTION_SETTINGS_STEP));
    
      __out.push('">\n    <a>\n      <span>\n        <span class="icon-setup-settings font-20"></span>\n        <span class="nav-item-text">Notifications and Settings</span>\n      </span>\n      ');
    
      if (this.setup_for_step(Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.NOTIFICACTION_SETTINGS_STEP)) {
        __out.push('\n      <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}