import React, { useCallback } from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TID } from '@src/types/common';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container';

import Header from './header';
import { useAchTransactionsCollection } from './hooks';
import List from './list';
import { useAchDetailsSideView } from './side_view/side_view';

interface IAchTransactionsProps {
  businessId: TID,
  serviceId: TID,
}

const AchTransactions = ({
  businessId,
  serviceId,
}: IAchTransactionsProps): JSX.Element => {
  const achTransactionsCollection = useAchTransactionsCollection({ businessId });

  const detailsSideView = useAchDetailsSideView();
  const { openWithValue } = detailsSideView;

  const handleRowClick = useCallback((document: IAchTransaction) => {
    return openWithValue({
      businessId,
      serviceId,
      document,
    });
  }, [businessId, serviceId, openWithValue]);

  return (
    <DetailsRegion>
      <PageContainer
        rightSideView={ (
          <detailsSideView.Component
            { ...detailsSideView.props }
          />
        ) }
      >
        <Header />
        <List
          collection={ achTransactionsCollection }
          onRowClick={ handleRowClick }
        />
      </PageContainer>
    </DetailsRegion>
  );
};

export default AchTransactions;
