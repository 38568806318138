import React, { useEffect, useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  ICreateCompliancePoliciesParams,
  IEditCompliancePoliciesParams,
  useCreateCompliancePolicy,
  useDeleteCompliancePolicy,
  useUpdateCompliancePolicy,
} from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { IVendor } from '@src/types/business_vendors';

import Spinner from '@src/components/ui/spinner';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Header from './compliance_header';
import { useGetCompliancePolicy } from './hooks';
import RuleListView from './rule_list_view';
import SideView from './sideview';
import { IAccountsPayablePolicy, IInitialData, ITransaction } from './types';

const initData: IInitialData = {
  id:             'new',
  amount:         '',
  amountOperator: 'greater_than',
  vendor:         null,
};

const Rules = () => {
  const APPROVER_FOR_UPLOADER = 'approval_for_uploader';
  const [condition, setCondition] = useState<string>('');
  const [openSideview, setOpenSideview] = useState<boolean>(false);
  const { id, activeSubscription } = useBusinessContext();
  const hasAdvancePlan =
        activeSubscription
        && activeSubscription.active
        && activeSubscription.availableFeatures.includes('compliance_policies');
  const compliancePolicies = useGetCompliancePolicy(id, APPROVER_FOR_UPLOADER);
  const [initialData, setInitialData] = useState<IInitialData | null>(null);
  const [policies, setPolicies] = useState<
        IAccountsPayablePolicy | undefined
    >(compliancePolicies);
  const queryClient = useQueryClient();
  const { mutate: deleteCompliancePolicyMutate, isLoading: deleteIsLoading } =
        useDeleteCompliancePolicy();
  const { mutate: createCompliancePolicyMutate, isLoading: createIsLoading } =
        useCreateCompliancePolicy();
  const { mutate: updateCompliancePolicyMutate, isLoading: updateIsLoading } =
        useUpdateCompliancePolicy();

  const handleClickDone = useCallback((policyType : string) => {
    if (initialData?.vendor === null && initialData?.amount === '' && initialData?.amountOperator === 'greater_than') {
      setInitialData(null);
      return;
    }
    if (initialData !== null && initialData.id === 'new') {
      let params: ICreateCompliancePoliciesParams;
      if (initialData.amount !== '') {
        params = {
          business_id:            id,
          policy_type:            policyType,
          approve_all_invoice:    compliancePolicies?.approveAllInvoice,
          compliance_policy_rule: {
            vendor_id:      initialData?.vendor?.id,
            amount:         initialData?.amount,
            amountOperator: initialData?.amountOperator,
          },
        };
      } else {
        params = {
          business_id:            id,
          policy_type:            policyType,
          approve_all_invoice:    compliancePolicies?.approveAllInvoice,
          compliance_policy_rule: {
            vendor_id: initialData?.vendor?.id,
          },
        };
      }
      createCompliancePolicyMutate(params, {
        onSuccess: async () => {
          setInitialData(null);
          await queryClient.invalidateQueries(
            QueryKey.accountsPayableServiceDocuments,
          );
          toastr.success('Successfully created', 'Create Successful');
        },
        onError: () => {
          toastr.error('Failed to create rule', 'Error');
        },
      });
    } else if (initialData !== null && initialData.id !== 'new') {
      let params: IEditCompliancePoliciesParams;
      if (initialData.amount !== '') {
        params = {
          id:                     initialData?.id,
          business_id:            id,
          policy_type:            policyType,
          compliance_policy_rule: {
            vendor_id:      initialData?.vendor?.id,
            amount:         initialData?.amount,
            amountOperator: initialData?.amountOperator,
          },
        };
      } else {
        params = {
          id:                     initialData?.id,
          business_id:            id,
          policy_type:            policyType,
          compliance_policy_rule: {
            vendor_id: initialData?.vendor?.id,

          },
        };
      }

      updateCompliancePolicyMutate(params, {
        onSuccess: async () => {
          setInitialData(null);
          await queryClient.invalidateQueries(
            QueryKey.accountsPayableServiceDocuments,
          );
          toastr.success('Successfully updated', 'Update Successful');
        },
        onError: () => {
          toastr.error('Failed to updated rule', 'Error');
        },
      });
    }
  }, [compliancePolicies?.approveAllInvoice,
    createCompliancePolicyMutate,
    id,
    initialData,
    queryClient,
    updateCompliancePolicyMutate]);

  const handleAddEditClick = useCallback((row: IInitialData | null) => {
    setOpenSideview(false);
    if (row) {
      setInitialData(row);
    } else {
      setInitialData(initData);
    }
  }, []);

  useEffect(() => {
    if (compliancePolicies) {
      setPolicies(compliancePolicies);
    }
  }, [compliancePolicies]);

  const handleAddConditionSelected = useCallback(
    (selectedCondition: string, open: boolean) => {
      setCondition(selectedCondition);
      setOpenSideview(open);
    },
    [],
  );

  const handleTransactionSelected = useCallback(
    (tranData: ITransaction) => {
      if (tranData.operator && tranData.amount) {
        setInitialData((prev: IInitialData | null) => {
          if (prev === null) return null;
          return {
            ...prev,
            amountOperator: tranData.operator,
            amount:         tranData.amount,
          };
        });
        setOpenSideview(false);
      } else {
        setOpenSideview(true);
      }
    },
    [],
  );

  const handleOnTransactionDeleted = useCallback(
    (deleteId: number) => {
      deleteCompliancePolicyMutate(
        { id: deleteId, business_id: id, policy_type: APPROVER_FOR_UPLOADER },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries(
              QueryKey.accountsPayableServiceDocuments,
            );
            toastr.success(
              'Successfully deleted',
              'Delete Successful',
            );
          },
          onError: () => {
            toastr.error('Failed to delete policy entry.', 'Error');
          },
        },
      );
    },
    [deleteCompliancePolicyMutate, id, queryClient],
  );

  const handleDeleteTransaction = useCallback(() => {
    setInitialData((prev: IInitialData | null) => {
      if (prev === null) return null;
      return {
        ...prev,
        amount:         '',
        amountOperator: 'greater_than',
      };
    });
    setOpenSideview(false);
  }, []);

  const handleOnVendorSelected = useCallback((selectedVendor: IVendor) => {
    if (selectedVendor) {
      setInitialData((prev: IInitialData | null) => {
        if (prev === null) return null;
        const { id: selectedVendorId, name, email, phone, imageUrl } = selectedVendor;
        return {
          ...prev,
          vendor: {
            id:       selectedVendorId,
            name,
            email:    email || '',
            phone:    phone || '',
            imageUrl: imageUrl || null,
          },
        };
      });
      setOpenSideview(false);
    } else {
      setOpenSideview(true);
    }
  }, []);

  const handleVendorDeleted = useCallback(() => {
    setInitialData((prev: IInitialData | null) => {
      if (prev === null) return null;
      return {
        ...prev,
        vendor: null,
      };
    });
  }, []);

  if (deleteIsLoading || createIsLoading || updateIsLoading) {
    return <Spinner />;
  }

  return (
    <div className="receipt-settings-detail approval-rules-detail show-right-side">
      <div className="approval-rules-container">
        <Header />
        <h2 className="rule-title">
          Approval Restriction for Uploader
        </h2>
        <p className="font-14 m-t-10 m-b-15">
          The uploader of an invoice is going to approve under the following rules.
        </p>
        <div className="basic-rules-wrapper">
          <RuleListView
            approverPolicy={ policies }
            businessId={ id }
            handleAddEditClick={ handleAddEditClick }
            handleClickDone={ handleClickDone }
            handleDeleteTransaction={ handleDeleteTransaction }
            handleOnTransactionDeleted={ handleOnTransactionDeleted }
            handleVendorDeleted={ handleVendorDeleted }
            hasAdvancePlan={ hasAdvancePlan }
            initialData={ initialData }
            openSideview={ handleAddConditionSelected }
            policyType={ APPROVER_FOR_UPLOADER }
            onConditionSelected={ handleAddConditionSelected }
          />
          {hasAdvancePlan && (
            <RuleListView
              isAdvanceRule
              approverPolicy={ policies }
              businessId={ id }
              handleAddEditClick={ handleAddEditClick }
              handleClickDone={ handleClickDone }
              handleDeleteTransaction={ handleDeleteTransaction }
              handleOnTransactionDeleted={
                                handleOnTransactionDeleted
                            }
              handleVendorDeleted={ handleVendorDeleted }
              hasAdvancePlan={ hasAdvancePlan }
              initialData={ initialData }
              openSideview={ handleAddConditionSelected }
              policyType={ APPROVER_FOR_UPLOADER }
              onConditionSelected={ handleAddConditionSelected }
            />
          )}
        </div>
      </div>
      {openSideview && condition && (
        <DetailsRegion>
          <div
            className="rules-right-side-container overflow-y-hidden"
            id="rules-right-side-region"
          >
            <div className="approval-rules-detail">
              <div
                className="rules-right-side-container"
                id="rules-right-side-region"
              >
                <SideView
                  businessId={ id }
                  closeSideView={ () => setOpenSideview(false) }
                  handleTransactionSelected={
                                      handleTransactionSelected
                                  }
                  initialData={ initialData }
                  selectedCondition={ condition }
                  onVendorSelected={ handleOnVendorSelected }
                />
              </div>
            </div>
          </div>
        </DetailsRegion>
      )}
    </div>
  );
};

export default Rules;
