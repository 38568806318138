import { number, object, string } from 'yup';

import { IAccountBigType, IAccountSubType } from '@src/types/account_types';
import { TAmount, TDate, TID } from '@src/types/common';

// Define a type that includes all the original properties of schemaObject plus the optional qbo property
type SchemaObjectType = {
  name: ReturnType<typeof string>;
  startImportDate: ReturnType<typeof string>;
  last4: ReturnType<typeof string>;
  bankAccount: ReturnType<typeof object>;
  qbo?: ReturnType<typeof object>;
};

const accountSettingsValidation = (mappingBankAccountsReconciliationCenterFeatureflag:boolean | string | undefined) => {
  const schemaObject:SchemaObjectType = {
    name:            string().trim().required('Account Name is required.'),
    startImportDate: string().required('Starting Date is required.'),
    last4:           string().test((value, validationContext) => {
      const { createError,
        parent: {
          bankAccount,
        } } = validationContext;

      if (bankAccount && bankAccount.bankAccountLast4 === '') return true;
      if (value === '') return createError({ message: 'Last 4 digits is required' });
      if (value?.length !== 4) return createError({ message: 'Account number must 4 digits' });
      if (!/^[0-9]+$/.test(value)) return createError({ message: 'Account number must number' });

      return true;
    }),
    bankAccount: object()
      .when(
        'transactionImportType',
        { is: 'auto', then: (schema) => schema.shape({ bankAccountId: string().required('Bank Account is required.') }) },
      ),
  };

  if (!mappingBankAccountsReconciliationCenterFeatureflag) {
    schemaObject.qbo = object({
      id: string().nullable().trim().required('Chart of Accounts is required.'),
    });
  }

  return object(schemaObject);
};
interface IQBOData {
  id: string,
  name: string,
}

interface IBankAccountData {
  financialInstitutionName: string,
  financialInstitutionLogo: string,
  bankAccountId: TID,
  bankAccountName: string,
  bankAccountLast4: string,
}

interface IAccountSettingsInput {
  name: string,
  last4: string,
  startImportDate: string,
  startBalance: TAmount,
  transactionImportType: 'auto' | 'manual' | undefined,
  qbo: IQBOData,
  bankAccount?: IBankAccountData,
  accountOwner: string,
  plaidStartImportDate?: TDate,
}

interface IAddChartOfAccountData {
  accountType?: IAccountBigType['code'],
  name?: string,
  number?: string,
  parentId?: TID,
  hasParent: 'true' | 'false',
  subType?: IAccountSubType['subtypeCode'],
  mappingClassIds?: TID[]
}

const addChartOfAccountValidation = object({
  accountType:        string().nullable().required('Account Type is required.'),
  name:               string().trim().required('Category Name is required.'),
  showParentCategory: string().oneOf(['true', 'false']),
  parentId:           number().nullable()
    .when(
      'hasParent',
      { is: 'true', then: (schema) => schema.required('Parent Category is required.') },
    ),
  subType: string().nullable().required('Detail Type is required.'),
});

export {
  IAccountSettingsInput,
  IAddChartOfAccountData,
  IQBOData,
  IBankAccountData,
  accountSettingsValidation,
  addChartOfAccountValidation,
};
