import React from 'react';

import { Routes, Route } from 'react-router-dom';

import DimensionDetails from './business_metric_dimension_details';
import BusinessMetricProvider from './business_metric_provider';
import Details from './business_metric_value_details';
import BusinessMetricRootPage from './details';
import ForReviewMetrics from './for_review';

interface IMetricsServiceValuesRouterProps {
  isIndustryMetrics: boolean,
}

const MetricsServiceValuesRouter = ({
  isIndustryMetrics,
}:IMetricsServiceValuesRouterProps): JSX.Element | null => {
  return (
    <Routes>
      <Route path="businesses/:businessId/metrics_service">
        <Route element={ <BusinessMetricProvider /> } path=":businessMetricId">
          <Route path="values">
            <Route element={ <DimensionDetails /> } path=":date" />
            <Route index element={ <Details /> } />
          </Route>

        </Route>
        <Route element={ <ForReviewMetrics /> } path="for_review" />
        <Route
          index
          element={ (
            <BusinessMetricRootPage
              isIndustryMetrics={ isIndustryMetrics }
            />
          ) }
        />
      </Route>
    </Routes>
  );
};

export default React.memo(MetricsServiceValuesRouter);
