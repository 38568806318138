import {
  ICreatePortfolioParams,
  IGetPortfolioDashboardParams,
  IGetPortfolioDashboardResponse,
  IGetPortfolioDashboardsParams,
  IGetPortfolioDashboardsResponse,
  IPortfolioData
} from "@src/types/portfolio";
import { camelizeKeys, underscoreKeys } from "@src/utils/transform_keys";

import { apiGet, apiPost } from "../helpers";

const createPortfolio = (params: ICreatePortfolioParams):
  Promise<IPortfolioData> => {
  return apiPost(
    '/api/v1/portfolios',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IPortfolioData);
};

const getPortfolioList = (): Promise<IPortfolioData> => {
  return apiGet('/api/v1/portfolios')
    .then((data) => camelizeKeys(data) as IPortfolioData);
};

const getPortfolioDashboards = (
  params: IGetPortfolioDashboardsParams,
): Promise<IGetPortfolioDashboardsResponse> => {
  return apiGet(
    `/dashboards/api/v1/portfolio_dashboards`,
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetPortfolioDashboardsResponse);
};

const getPortfolioDashboardById = (
  params: IGetPortfolioDashboardParams,
): Promise<IGetPortfolioDashboardResponse> => {
  return apiGet(
    `/dashboards/api/v1/portfolio_dashboards/${params.id}`,
  ).then((data) => camelizeKeys(data) as IGetPortfolioDashboardResponse);
};

export {
  createPortfolio,
  getPortfolioList,
  getPortfolioDashboardById,
  getPortfolioDashboards
}