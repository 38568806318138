import React, { ReactNode } from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

interface IBulkActionsDropDownProps {
  children: ReactNode,
  title?: string,
  icon?: ReactNode,
}

const BulkActionsDropDown = ({ children, title, icon }: IBulkActionsDropDownProps) => {
  return (
    <ActionsDropdown isShowArrow icon={ icon } title={ title }>
      { children }
    </ActionsDropdown>
  );
};

export default React.memo(BulkActionsDropDown);
