import React from 'react';

import { TSection } from '@src/types/common';

import { useItemsSelector } from '@src/components/utils_v2/items_selector';

import styles from '../styles.module.scss';

interface IBulkActionsSelectedItemsProps {
  itemCount?: number;
  section?: TSection;
}

const BulkActionsSelectedItems = ({ itemCount, section }: IBulkActionsSelectedItemsProps) => {
  const { selected } = useItemsSelector(section);

  return (
    <p className={ styles['select-item'] }>
      { itemCount || selected.length }
      {' '}
      Selected
    </p>
  );
};

export default React.memo(BulkActionsSelectedItems);
