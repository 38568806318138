import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetManagementGroupMembersParams,
  IGetManagementGroupMembersResponse,
  IGetAllUsersParams,
  IGetAllUsersResponse,
  IUpdateUserSettingsParams,
  IGetCurrentAdvisorsResponse,
  getManagementGroupMembers,
  getAllUsers,
  updateUserSettings,
  getCurrentAdvisor,
  IGetAllPortfolioUsersParams,
  getAllPortfolioUsers,
} from '@src/requests/users';

const useGetManagementGroupMembers = (params: IGetManagementGroupMembersParams) => {
  return useQuery<IGetManagementGroupMembersResponse, Error>(
    [QueryKey.managementGroupUsers, params],
    () => getManagementGroupMembers(params),
  );
};

const useGetAllUsers = (params: IGetAllUsersParams) => {
  return useQuery<IGetAllUsersResponse, Error>(
    [QueryKey.allUsers, params],
    () => getAllUsers(params),
  );
};

const useGetAllPortfolioUsers = (params: IGetAllPortfolioUsersParams) => {
  return useQuery<IGetAllUsersResponse, Error>(
    [QueryKey.portfolioUsers, params],
    () => getAllPortfolioUsers(params),
  );
};

const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateUserSettingsParams>(
    updateUserSettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.currentAdvisor);
      },
    },
  );
};

const useGetCurrentAdvisor = () => {
  return useQuery<IGetCurrentAdvisorsResponse, Error>(
    [QueryKey.currentAdvisor],
    () => getCurrentAdvisor(),
  );
};

export {
  useGetManagementGroupMembers,
  useGetAllUsers,
  useGetAllPortfolioUsers,
  useUpdateUserSettings,
  useGetCurrentAdvisor,
};
