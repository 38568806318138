module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="transaction-list-region" class="transaction-list-region">\n  <div>\n    <p class="m-b-20 font-16 inline-block">\n    </p>\n    <div class="p-r-20">\n      <div class="inline-block m-b-10">\n        <span class="font-24 font-bold">Flagged Transactions</span>\n      </div>\n      <div class="pull-right">\n        <button id="export-flagged-transaction-btn" class="export-csv-btn">\n          <i class="icon icon-upload m-l-3 m-r-10"></i> <span>Export CSV</span>\n        </button>\n      </div>\n    </div>\n    <div class="p-r-20">\n      <div id="export-flagged-transaction-alert" class="alert alert-info hidden">\n        <span class="icon icon-check_circle_large font-18 m-r-8 relative"></span>We\'ve started preparing your report. It can take few seconds to get it done. Once report is ready and available, it will start showing up in the <a href="/data_export">Data Export</a> section.\n      </div>\n    </div>\n    <div class="table-responsive transactions-table-wrapper p-r-20">\n      <div class="panel panel-default">\n        <div class="transaction-filter-container"></div>\n        <div class="uncategorized-items-table"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div id="transaction-right-side-region" class="transaction-right-side-region flagged_transaction-right-side-region hidden"></div>\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}