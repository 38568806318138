import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

interface IProgressModalProps extends IUseModalProps {
  doneCount: number;
  totalCount: number;
}

const ProgressModal = ({
  isOpen,
  doneCount,
  totalCount,
}: IProgressModalProps): JSX.Element => {
  const percent = Math.floor((doneCount * 100) / totalCount);

  return (
    <Modal
      show={ isOpen }
      title="Categorize Transaction"
    >
      <Modal.Body>
        <div>
          <p className="p-b-0 text-center m-b-20">
            Processing Transaction
            {' '}
            <b>
              { doneCount }
              /
              { totalCount }
            </b>
          </p>
          <div className="progress">
            <div className="progress-bar" style={ { width: `${percent}%` } } />
          </div>
          <p className="p-b-0 text-center">
            Please wait while we process your transaction...
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const useProgressModal = makeUseModal(ProgressModal);
export default useProgressModal;
