import React, { useState } from 'react';

import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { verifyTransactionServiceDocument } from '@src/requests/transaction_service_documents';
import { ITransactionServiceDocument, IUpdateTransactionServiceDocumentData } from '@src/types/transaction_service_documents';

import AskDocytAI from '@src/components/reconciliation_center/view_transaction_context/askDocytAI';
import { TabItem } from '@src/components/ui_v2/tabs';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import useProgressModal from './modals/progress_modal';
import useQuickbooksWarningModal from './modals/quickbooks_warning_modal';
import useResultModal from './modals/result_modal';
import Expense from './transactionType/expense';

import styles from '../styles.module.scss';

interface ISidebarProps {
  onClose: () => void;
  rowData: ITransactionServiceDocument;
  businessId: number;
  isLoading: boolean;
  onRefreshData: () => void;
}

const Sidebar = ({
  onClose,
  rowData,
  businessId,
  isLoading,
  onRefreshData,
}: ISidebarProps) => {
  const progressModal = useProgressModal();
  const resultModal = useResultModal();
  const warningModal = useQuickbooksWarningModal();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [doneCount, setDoneCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const business = useBusinessContext();

  const transactionContextData = {
    id:                    rowData.id,
    businessId,
    documentId:            rowData.documentId,
    isFlaggedOrHasMessage: !!rowData.flaggedState || !!rowData.transactionContextMessage,
    transactionDesc:       rowData.description,
    aiContext:             rowData.descriptionExplained || '',
    clientContext:         rowData.transactionContextMessage || '',
  };

  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_UNCATEGORIZED,
  );

  if (!featureQuery.data) return null;

  const handleCategorize = async () => {
    // Check if the transaction date is before the reconciliation start date
    // eslint-disable-next-line max-len
    if (business.reconciliationStartDate && moment(rowData.transactionDate) < moment(business.reconciliationStartDate)) {
      warningModal.open();
      return;
    }

    progressModal.open();
    setDoneCount(0);

    try {
      const updateData: IUpdateTransactionServiceDocumentData = {
        userVendorId:         rowData.vendorId,
        userChartOfAccountId: rowData.chartOfAccountId,
        doNotLearn:           rowData.doNotLearn,
        noPushQbo:            rowData.noPushQbo,
        noDocumentRequired:   rowData.noDocumentRequired,
        // eslint-disable-next-line max-len
        chargebackWonAmount:  rowData.chargebackWonAmount ? Number(rowData.chargebackWonAmount) : null,
        // eslint-disable-next-line max-len
        chargebackLostAmount: rowData.chargebackLostAmount ? Number(rowData.chargebackLostAmount) : null,
      };

      await verifyTransactionServiceDocument({
        transactionId: rowData.id,
        data:          updateData,
      });
      setDoneCount(1);
      setSuccess(true);
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
    } finally {
      progressModal.props.onDone?.();
      resultModal.open();
    }
  };

  const Verifiy = (boolean: boolean) => {
    setIsButtonDisabled(boolean);
  };

  return (
    <div className={ styles['sidebar-container'] }>
      <div className={ styles['sidebar-header'] }>
        <span>Categorize Transactions</span>
        <NotAChargebackIcon pointer fontSize={ 20 } onClick={ onClose } />
      </div>
      <div className={ styles['sidebar-body'] }>
        {isLoading ? (
          <div className={ styles['loading-container'] }>
            <div className={ styles['loading-spinner'] } />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div className={ styles['sidebar-tabs'] }>
              <TabItem
                activeTab={ { label: 'Data', value: 'data' } }
                tab={ { label: 'Data', value: 'data' } }
                onTabChange={ () => {} }
              />
              <TabItem
                activeTab={ { label: 'Data', value: 'data' } }
                tab={ { label: 'Chat', value: 'chat' } }
                onTabChange={ () => {} }
              />
            </div>
            <div className={ styles['sidebar-chat-content'] }>Chat Tab Content</div>
            <div className={ styles['sidebar-data-content'] }>
              <div className={ styles['sidebar-common-content'] }>
                <div className={ styles['sidebar-data'] }>
                  <div>Date</div>
                  <input
                    disabled
                    className={ styles['sidebar-data-input'] }
                    type="text"
                    value={ moment(rowData.transactionDate).format('MM/DD/YYYY') }
                  />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Amount</div>
                  <input disabled className={ styles['sidebar-data-input'] } type="text" value={ rowData.amount } />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Description</div>
                  <input disabled className={ styles['sidebar-data-input'] } type="text" value={ rowData.description } />
                </div>
                <AskDocytAI transactionContextData={ transactionContextData } />
              </div>
              <Expense
                Verifiy={ Verifiy }
                businessId={ businessId }
                rowData={ rowData }
              />
              <div className={ styles['sidebar-footer'] }>
                <button
                  className={ isButtonDisabled ? styles['button-disabled'] : '' }
                  disabled={ isButtonDisabled }
                  type="button"
                  onClick={ handleCategorize }
                >
                  Categorize Transaction
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ 1 }
        { ...progressModal.props }
      />

      <resultModal.Component
        errorMessage={ errorMessage }
        success={ success }
        onClose={ onClose }
        onRefreshData={ onRefreshData }
        { ...resultModal.props }
      />

      <warningModal.Component
        actionDateStr={ moment(rowData.transactionDate).format('MM/DD/YYYY') }
        actionDateTitle="Transaction Date"
        actionType="updated"
        documentType="transaction"
        paymentAccountStartDateStr=""
        reconciliationClosingDateStr={ moment(business.reconciliationStartDate).format('MM/DD/YYYY') }
        title=" Verify Bank Transaction"
        { ...warningModal.props }
      />
    </div>
  );
};

export default React.memo(Sidebar);
