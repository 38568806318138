import React, { useMemo } from 'react';

import { useGetAccountsPayableRelatedJournal } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetDocument } from '@src/hooks/queries/documents';
import { TID } from '@src/types/common';

import CollapseSection from '@src/components/ui/form/collapse_section';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Tabs from './tabs';

interface IRelatedJournalEntriesProps {
  documentId: TID;
}

const RelatedJournalEntriesSection = ({
  documentId,
}: IRelatedJournalEntriesProps): JSX.Element | null => {
  const { data: document } = useGetDocument(documentId);
  const { data: journalData } = useGetAccountsPayableRelatedJournal(documentId);
  const intercompanyEnabled = useMemo(() => window.configData
    .launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.INTERCOMPANY_AUTOMATION_FLAG), []);

  /**
   * If the journal data is not available or all the three tabs are empty,
   * the `RELATED JOURNAL ENTRIES` section should not be rendered.
   */
  if (
    !journalData
    || !document
    || (!journalData.adjustmentEntry
      && journalData.accrualAdjustmentEntries.length === 0
      && !(intercompanyEnabled && journalData.intercompanyAdjustmentEntries.length > 0))
  ) {
    return null;
  }

  return (
    <CollapseSection title="RELATED JOURNAL ENTRIES">
      <DetailsRegion className="min-height-0">
        <Tabs document={ document } relatedJournal={ journalData } />
      </DetailsRegion>
    </CollapseSection>
  );
};

export default React.memo(RelatedJournalEntriesSection);
