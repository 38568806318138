import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '../styles.module.scss';

interface IBulkActionProps {
  danger?: boolean,
  disabled?: boolean,
  icon?: JSX.Element,
  title?: string,
  onSelect?: () => void;
}

const BulkAction = ({ danger, disabled, icon, title, onSelect }: IBulkActionProps) => {
  return (
    <ActionsDropdown.Action
      className={ styles['dropdown-item'] }
      danger={ danger }
      disabled={ disabled }
      icon={ icon }
      title={ title }
      onSelect={ onSelect }
    />
  );
};

export default React.memo(BulkAction);
