import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useIntegrationRunsChannel } from '@src/hooks/faye/integration_runs';
import {
  IGetIntegrationsRunDownloadUrlsParams,
  IGetIntegrationsRunDownloadUrlsResponse,
  getIntegrationsRunDownloadUrls,
  IGetIntegrationsRunsParams,
  postResetIntegrationsRun,
  IGetIntegrationsRunsResponse,
  getIntegrationsRuns,
} from '@src/requests/integrations/runs';

const useGetIntegrationsRunDownloadUrls = (
  params: IGetIntegrationsRunDownloadUrlsParams,
) => {
  return useQuery<IGetIntegrationsRunDownloadUrlsResponse, Error>(
    [QueryKey.integrationsRunDownloadUrls, params.integrationId, params.integrationRunId],
    () => getIntegrationsRunDownloadUrls(params),
  );
};

const useResetIntegrationsRun = () => {
  return useMutation<void, Error, IGetIntegrationsRunsParams>(
    postResetIntegrationsRun,
  );
};

const useGetIntegrationsRuns = (
  params: IGetIntegrationsRunsParams,
) => {
  const enabled = params.integrationId !== 0;
  const query = useQuery<IGetIntegrationsRunsResponse, Error>(
    [QueryKey.integrationsRuns, params],
    () => getIntegrationsRuns(params),
    { enabled },
  );

  const { refetch } = query;
  const handleEventReceived = useCallback(() => {
    refetch();
  }, [refetch]);

  useIntegrationRunsChannel(params.integrationId, handleEventReceived, enabled);
  return query;
};

export {
  useGetIntegrationsRunDownloadUrls,
  useResetIntegrationsRun,
  useGetIntegrationsRuns,
};
