module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'relative\'>\n  ');
    
      if (!(this.client_advanced_mode || (!this.isAccountantMember && !this.canShowCategorization))) {
        __out.push('\n  <div class="document-field-item document-field-name">');
        __out.push(__sanitize(this.name));
        __out.push('</div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.isAccountantMember || this.canShowCategorization) {
        __out.push('\n    <div class="document-field-item category-field-value document-field-value ');
        __out.push(__sanitize(this.data_type));
        __out.push('">\n    <a class="pointer line-height-26" id="split-account-dropdown-btn">\n      ');
        if (this.service_document_splits_count > 1) {
          __out.push('\n        ');
          if (this.is_invoice_document || !this.is_category_readonly) {
            __out.push('\n          ');
            __out.push(__sanitize(this.service_document_splits_count));
            __out.push(' Splits\n        ');
          } else {
            __out.push('\n          View Category\n        ');
          }
          __out.push('\n      ');
        } else if (this.service_document_splits_count === 1) {
          __out.push('\n        ');
          __out.push(__sanitize((this.is_invoice_document || !this.is_category_readonly) ? '1 Split' : 'View Category'));
          __out.push('\n      ');
        }
        __out.push('\n    </a>\n    ');
        if (this.service_document_splits_count === 0) {
          __out.push('\n      ');
          if (this.predicted_chart_of_account_line_item) {
            __out.push('\n        ');
            if (this.is_invoice_document && this.in_queue_list) {
              __out.push('\n          <div class="category-name-field over-flow-hidden" id="category-name-field-');
              __out.push(__sanitize(this.document_id));
              __out.push('" data-toggle="tooltip" data-placement="bottom">');
              __out.push(__sanitize(this.predicted_chart_of_account_line_item));
              __out.push('</div>\n        ');
            } else {
              __out.push('\n          <div>');
              __out.push(__sanitize(this.predicted_chart_of_account_line_item));
              __out.push('</div>\n        ');
            }
            __out.push('\n        <a class="pointer line-height-26" id="split-account-dropdown-btn">\n          ');
            __out.push(__sanitize(this.predicted_chart_of_account_line_item ? 'Edit' : 'Select Category'));
            __out.push('\n        </a>\n      ');
          } else if (this.service_line_items_count > 1) {
            __out.push('  \n        <a class="pointer line-height-26" id="split-account-dropdown-btn">\n          ');
            __out.push(__sanitize(this.service_line_items_count));
            __out.push(' Splits\n        </a>\n      ');
          } else {
            __out.push('\n        ');
            if (this.is_invoice_document && this.in_queue_list) {
              __out.push('\n          <div class="category-name-field over-flow-hidden" id="category-name-field-');
              __out.push(__sanitize(this.document_id));
              __out.push('" data-toggle="tooltip" data-placement="bottom">');
              __out.push(__sanitize(this.value));
              __out.push('</div>\n        ');
            } else {
              __out.push('\n          <div>');
              __out.push(__sanitize(this.value));
              __out.push('</div>\n        ');
            }
            __out.push('\n        <a class="pointer line-height-26" id="split-account-dropdown-btn">\n          ');
            if (this.is_category_readonly) {
              __out.push('\n          View Category\n          ');
            } else {
              __out.push('\n          ');
              __out.push(__sanitize(this.value ? 'Edit' : 'Select Category'));
              __out.push('\n          ');
            }
            __out.push('\n        </a>\n    ');
          }
          __out.push('\n  </div>\n  ');
        }
        __out.push('\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}