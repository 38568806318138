import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { makeUseModal, IUseModalProps } from '@src/hooks/modal';
import { useUpdatePaymentAccount } from '@src/hooks/queries/payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { formatDate } from '@src/utils/date_helpers';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAccountSettingsInput } from './schema';

type TOpenValue = {
  paymentAccount: IPaymentAccount | undefined;
  mappingBankAccountsReconciliationCenterFeatureflag?: boolean;
}

const EmployeeSettings = ({
  isOpen = true,
  openValue,
  onCancel,
  onDone,
}: IUseModalProps<TOpenValue>): JSX.Element => {
  const business = useBusinessContext();

  const update = useUpdatePaymentAccount();
  const { mutate, isLoading } = update;

  const handleSubmit = useCallback((data: IAccountSettingsInput) => {
    if (!openValue?.paymentAccount) return;

    mutate({
      id:             openValue?.paymentAccount?.id,
      businessId:     business.id,
      paymentAccount: {
        name: data.name,
        ...(openValue?.mappingBankAccountsReconciliationCenterFeatureflag ? {} : {
          qboId:   data.qbo.id,
          qboName: data.qbo.name,
        }),
        startImportDate: formatDate(data.startImportDate, 'YYYY/MM/DD'),
        startBalance:    data.startBalance,
        last4:           data.last4,
        accountOwner:    data.accountOwner,
      },
    }, {
      onSuccess: onDone,
    });
  }, [openValue?.paymentAccount, openValue?.mappingBankAccountsReconciliationCenterFeatureflag, mutate, business.id, onDone]);

  return (
    <SideView.Form
      isRoot
      isOpen={ isOpen }
      title="Account Settings"
      onCancel={ onCancel }
    >
      { ({ formId }) => (
        <div className="account-settings-view">
          <MutationStatus mutation={ update } />
          {isLoading && (<Spinner />)}
          <SideView.Subtitle>{ openValue?.paymentAccount?.name }</SideView.Subtitle>
          <p>{ business.name }</p>

          <hr />

          { (openValue?.paymentAccount) && (
            <Form
              // This `key` property is required here to rebuild whole subtree on account change
              // (including rebuilding form with new values and removing child side view
              key={ openValue?.paymentAccount?.id }
              businessId={ business.id }
              formId={ formId }
              mappingBankAccountsReconciliationCenterFeatureflag={ openValue?.mappingBankAccountsReconciliationCenterFeatureflag }
              paymentAccount={ openValue?.paymentAccount }
              onSubmit={ handleSubmit }
            />
          )}
        </div>
      )}
    </SideView.Form>
  );
};

const MemoizedSetupAccount = React.memo(EmployeeSettings);
const useEmployeeSettingSideView = makeUseModal<typeof MemoizedSetupAccount, TOpenValue>(MemoizedSetupAccount);

export {
  useEmployeeSettingSideView,
  EmployeeSettings as default,
};
