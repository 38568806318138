import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IClientBusiness, TClientBusinessesSortColumn } from '@src/types/clients';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import TableRow from './table_row';

import styles from './styles.module.scss';

interface IClientBusinessTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  clientBusinesses: IClientBusiness[],
  sorting: ISorting<TClientBusinessesSortColumn>,
}

const ClientBusinessTable = ({
  query,
  clientBusinesses,
  sorting,
}: IClientBusinessTableProps) => {
  return (
    <Table
      className={ styles['client-businesses-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell
            className={ styles['client-businesses-table-header'] }
            widthPercent={ 5 }
          />
          <Table.HCell<TClientBusinessesSortColumn>
            className={ styles['client-businesses-table-header'] }
            columnName="name"
            sorting={ sorting }
            widthPercent={ 28 }
          >
            Client
          </Table.HCell>
          <Table.HCell<TClientBusinessesSortColumn>
            className={ styles['client-businesses-table-header'] }
            columnName="inbox"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Inbox
          </Table.HCell>
          <Table.HCell<TClientBusinessesSortColumn>
            className={ styles['client-businesses-table-header'] }
            columnName="accounts_payable"
            sorting={ sorting }
            widthPercent={ 12 }
          >
            Accounts Payable
          </Table.HCell>
          <Table.HCell<TClientBusinessesSortColumn>
            className={ styles['client-businesses-table-header'] }
            columnName="receipts"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Receipts
          </Table.HCell>
          <Table.HCell<TClientBusinessesSortColumn>
            className={ styles['client-businesses-table-header'] }
            columnName="transactions"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Transactions
          </Table.HCell>
          <Table.HCell
            className={ styles['client-businesses-table-header'] }
            widthPercent={ 10 }
          >
            Business Admins
          </Table.HCell>
          <Table.HCell
            className={ styles['client-businesses-table-header'] }
            widthPercent={ 10 }
          >
            Account Managers
          </Table.HCell>
          <Table.HCell
            className="client-actions-column"
            widthPercent={ 3 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body className={ styles['client-businesses-table-body'] }>
        {
          clientBusinesses.map((clientBusiness) => (
            <TableRow
              key={ clientBusiness.clientBusinessId }
              clientBusiness={ clientBusiness }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(ClientBusinessTable);
