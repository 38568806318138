import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import flatten from 'lodash/flatten';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { useGetBusinessesByManagementGroup } from '@src/hooks/queries/businesses';
import { IGetBusinessesResponse } from '@src/requests/businesses';
import { TAccountType, TID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate, startOfMonthDate } from '@src/utils/date_helpers';

import Form from '@src/components/ui/form';

import AccountField from './account_field';
import { ISetupAccountData } from './schema';

import styles from '../../styles.module.scss';

interface ISetupAccountFormProps {
  accountType: TAccountType,
  formId: string,
  onSubmit: (data: ISetupAccountData) => void;
  managementGroupId: TID
}

const SetupAccountForm = ({
  accountType,
  managementGroupId,
  formId,
  onSubmit,
}: ISetupAccountFormProps): JSX.Element => {
  const businessQuery = useGetBusinessesByManagementGroup({ managementGroupId });
  const businesses = useMemo(() => {
    const businessPages =
      flatten((businessQuery.data?.pages || []).map((p: IGetBusinessesResponse) => p.collection));

    return businessPages.map((business) => {
      return { value: String(business.id), name: business.displayName };
    });
  }, [businessQuery.data?.pages]);

  const accountSettingsValidation = object({
    reconciliationPaymentAccount: object({
      id: string().nullable().trim().required('Account is required.'),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm<ISetupAccountData>({
    defaultValues: {
      startImportDate: formatDate(startOfMonthDate(new Date()), API_DATE_FORMAT),
      isMultiEntity:   'false',
    },
    mode:     'onBlur',
    resolver: yupResolver(accountSettingsValidation),
  });

  useEffect(() => {
    if (businesses.length > 0) {
      setValue('businessId', Number(businesses[0].value));
    }

    const subscription = watch((value, { name }) => {
      if (name === 'reconciliationPaymentAccount' && value.reconciliationPaymentAccount?.startImportDate) {
        setValue('startImportDate', value.reconciliationPaymentAccount.startImportDate);
      }
    });

    return () => subscription.unsubscribe();
  }, [businesses, setValue, watch]);

  const startImportDateController = useController({ name: 'startImportDate', control });
  const accountsField = useController({ name: 'reconciliationPaymentAccount', control });

  const multiEntityOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ];

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {accountType === 'credit_card' && (
        <>
          <div>
            <div>
              Does this Credit Card as employee cards used across multiple businesses?
            </div>
            <div className={ styles['radio-rows'] }>
              <Form.RadioCollectionField
                label=""
                { ...register('isMultiEntity') }
                options={ multiEntityOptions }
              />
            </div>
          </div>
          <hr />
        </>
      )}
      <p className={ styles['form-info'] }>
        Select the business and
        {accountType === 'credit_card' ? ' Credit Card' : ' Bank Account'}
        {' '}
        which you want to map to the financial connection.
      </p>
      <Form.SelectField
        label="Select Business"
        { ...register('businessId') }
        options={ businesses }
      />
      <AccountField
        accountType={ accountType }
        businessId={ watch('businessId') }
        error={ errors.reconciliationPaymentAccount?.id?.message }
        value={ accountsField.field.value }
        onChange={ accountsField.field.onChange }
      />
      <Form.DateField
        error={ errors.startImportDate?.message }
        label="Starting Date"
        placeholder="Starting Date"
        { ...startImportDateController.field }
      />
    </Form>
  );
};

export default SetupAccountForm;
