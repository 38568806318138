import React from 'react';

import { documentChatPath } from '@src/routes';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { currencyLocaleFormatter } from '@src/utils/currency';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import {
  BusinessIcon,
  CashIcon,
  ChatIcon,
  CreditCardIcon,
  EditAccountIcon,
  ForwardOIcon,
  MergeInvoicesIcon,
  PaidIcon,
  PayeeProfileIcon,
  SplitAcrossBusinessesIcon,
  SplitByPageIcon,
  SplitInvoicesIcon,
  SplitMultiplePaymentIcon,
  ThumbsDownIcon,
  TrashcanIcon,
  VendorCreditIcon,
  WrenchIcon,
} from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

type TInvoiceQueueListActionsProps = {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
};

const InvoiceQueueListActions = ({ accountsPayableServiceDocument }: TInvoiceQueueListActionsProps) => {
  const value = currencyLocaleFormatter(accountsPayableServiceDocument.invoiceAmount || '0.0');

  return (
    <ActionsDropdown isShowArrow className={ styles['actions-dropdown-button'] } title={ value }>
      <ActionsDropdown.LinkAction
        href={ documentChatPath(accountsPayableServiceDocument.documentId) }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
      />
      <ActionsDropdown.Action
        icon={ <EditAccountIcon fontSize={ 20 } /> }
        title="Edit Invoice"
      />
      <ActionsDropdown.Action
        icon={ <ThumbsDownIcon fontSize={ 20 } /> }
        title="Retract Approval"
      />
      <ActionsDropdown.Submenu
        icon={ <PaidIcon fontSize={ 20 } /> }
        title="Mark as Paid"
      >
        <ActionsDropdown.Action
          icon={ <CashIcon className="font-22" /> }
          title="Cash"
        />
        <ActionsDropdown.Action
          icon={ <CreditCardIcon className="font-22" variant="simple" /> }
          title="Credit Card"
        />
        <ActionsDropdown.Action
          icon={ <PaidIcon className="font-22" /> }
          title="Manual Check"
        />
        <ActionsDropdown.Action
          icon={ <BusinessIcon className="font-22" /> }
          title="Bank Account (ACH / Debit Card)"
        />
        <ActionsDropdown.Action
          icon={ <PaidIcon className="font-22" /> }
          title="Advanced Settlement..."
        />
        <ActionsDropdown.Action
          icon={ <VendorCreditIcon className="font-22" /> }
          title="Vendor Credit"
        />
      </ActionsDropdown.Submenu>
      <ActionsDropdown.Submenu
        icon={ <MergeInvoicesIcon fontSize={ 20 } /> }
        title="Split Invoice"
      >
        <ActionsDropdown.Action
          icon={ <SplitInvoicesIcon className="font-22" /> }
          title="Split Statement to Invoices"
        />
        <ActionsDropdown.Action
          icon={ <SplitMultiplePaymentIcon className="font-22" /> }
          title="Split Invoice to Multiple Payments"
        />
        <ActionsDropdown.Action
          icon={ <SplitAcrossBusinessesIcon className="font-22" /> }
          title="Split Invoice Across Businesses"
        />
        <ActionsDropdown.Action
          icon={ <SplitByPageIcon className="font-22" /> }
          title="Split Invoice by Page"
        />
      </ActionsDropdown.Submenu>
      <ActionsDropdown.Action
        icon={ <PayeeProfileIcon fontSize={ 20 } /> }
        title="View Vendor Profile"
      />
      <ActionsDropdown.Action
        icon={ <ForwardOIcon fontSize={ 20 } /> }
        title="Forward"
      />
      <ActionsDropdown.Action
        icon={ <WrenchIcon fontSize={ 20 } /> }
        title="Report an Issue"
      />
      <ActionsDropdown.Action
        danger
        icon={ <TrashcanIcon fontSize={ 20 } /> }
        title="Delete Invoice"
      />
    </ActionsDropdown>
  );
};

export default InvoiceQueueListActions;
