module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="pointer clickable-js display-flex text-center ');
    
      __out.push(__sanitize(this.payment_accounts_count > 1 ? 'has-employee-cards' : void 0));
    
      __out.push('">\n  ');
    
      if (this.account_type === 'bank') {
        __out.push('\n    <span class="icon icon-bank-account payment-account-primary-icon font-24">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n    </span>\n    <span class="icon icon-bank-account payment-account-employee-icon font-24">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n    </span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-credit-card-account payment-account-primary-icon font-24">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    <span class="icon icon-credit-card-account payment-account-employee-icon font-24">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="pointer clickable-js">');
    
      __out.push(__sanitize(this.accName));
    
      __out.push('</td>\n<td class="pointer clickable-js">');
    
      __out.push(__sanitize(this.accNo));
    
      __out.push('</td>\n');
    
      if (!this.mappingBankAccountsReconciliationCenterFeatureflag) {
        __out.push('\n  <!-- Condition to check if the feature flag for mapping bank accounts to the reconciliation center is not enabled -->\n  <td class="pointer clickable-js">\n    ');
        if (this.qbo_name) {
          __out.push(__sanitize(this.qbo_name));
        } else {
          __out.push('<span><a class="pointer link-chart-of-account-btn"><span class="icon-link-chart-of-account-icon vertical-align-middle font-20"></span><b class="m-l-5 vertical-align-middle">Link Account</b></a></span>');
        }
        __out.push('\n  </td>\n');
      }
    
      __out.push('\n');
    
      if (!this.for_onboarding_setup) {
        __out.push('\n  ');
        if (!this.type) {
          __out.push('\n    <td class="pointer clickable-js">');
          if (this.last_import_date) {
            __out.push(__sanitize(moment(this.last_import_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
          } else {
            __out.push('-');
          }
          __out.push('</td>\n    <td class="pointer clickable-js text-right"><span class="font-bold">');
          __out.push(__sanitize(this.totalAmount));
          __out.push('</span></td>\n    <td class="pointer clickable-js text-center">\n      ');
          if (this.is_being_disconnected) {
            __out.push('\n        <button class="btn btn-disconnecting" disabled>DISCONNECTING</button>\n      ');
          } else if (this.transaction_import_type) {
            __out.push('\n        <span class="transaction-import-type-flag ');
            __out.push(__sanitize(this.transaction_import_type));
            __out.push('">');
            __out.push(__sanitize(this.transaction_import_type.charAt(0).toUpperCase() + this.transaction_import_type.slice(1)));
            __out.push('</span>\n      ');
          } else {
            __out.push('\n        <button class="btn btn-finish-setup">FINISH SETUP</button>\n      ');
          }
          __out.push('\n    </td>\n    <td class="pointer clickable-js">\n      ');
          if (this.transaction_import_type === 'auto') {
            __out.push('\n        ');
            if (this.plaidDisconnected) {
              __out.push('\n          <span class="icon icon-plaid-icon-link-error font-18 vertical-align-middle"><span class="path1"></span><span class="path2"></span></span>\n        ');
            } else {
              __out.push('\n          <span class="icon icon-plaid-icon-active font-18 vertical-align-middle"><span class="path1"></span><span class="path2"></span></span>\n        ');
            }
            __out.push('\n      ');
          } else {
            __out.push('\n        <span class="icon icon-plaid-icon-deactive font-18 vertical-align-middle"><span class="path1"></span><span class="path2"></span></span>\n      ');
          }
          __out.push('\n    </td>\n    <td class="pointer clickable-js">\n      ');
          if (this.qbo_id && this.qbo_id !== "") {
            __out.push('\n        <span class="icon icon-qbo-on font-17 vertical-align-middle"></span>\n      ');
          } else {
            __out.push('\n        <span class="icon icon-qbo-error font-17 vertical-align-middle"></span>\n      ');
          }
          __out.push('\n    </td>\n  ');
        }
        __out.push('\n  <td><div class="payment-account-item-action-wrapper"></div></td>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}