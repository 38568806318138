import React from 'react';

import styles from '../styles.module.scss';

const PortfolioListHeader = () => {
  return (
    <div className={ styles['header-titles'] }>
      <div className={ styles['title-content'] }>
        <p>Report Type</p>
      </div>
      <div className={ styles['last-updated'] }>
        <p>Last Updated</p>
      </div>
      <div className={ styles['shared-with'] }>
        <p>Shared With</p>
      </div>
      <div className={ styles.action } />
    </div>  
  );
};

export default React.memo(PortfolioListHeader);
