import React from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import Cell, { ITableCellProps } from '../cell';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './styles.module.scss';

interface IAvatarLoadingCellProps extends Omit<ITableCellProps, 'children'> {}

const AvatarLoadingCell = ({
  className,
  ...cellProps
}: IAvatarLoadingCellProps) => {
  const classes = classNames(styles['avatar-skeleton'], className);

  return (
    <Cell { ...cellProps } className={ classes }>
      <Skeleton circle className={ styles.avatar } />
      <Skeleton className={ styles.text } />
    </Cell>
  );
};

export default AvatarLoadingCell;
