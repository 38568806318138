import React, { forwardRef, useCallback } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';

import styles from './styles.module.scss';

interface ISwitchInputProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  labelProps?: React.ComponentPropsWithoutRef<'label'>,
  title?: string,
  onChange?: (checked: boolean, value: string | undefined) => void,
}

const SwitchInput = forwardRef<HTMLInputElement, ISwitchInputProps>(({
  id,
  labelProps,
  title,
  onChange,
  ...props
}: ISwitchInputProps, ref) => {
  const inputId = useUniqueDomId('switch_input_');

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange(e.currentTarget.checked, e.currentTarget.value);
  }, [onChange]);

  const handleLabelClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={styles['switch-input']}>
      <label className={styles['switch']}>
        <input
          ref={ ref }
          id={ id || inputId }
          type="checkbox"
          onChange={ handleChange }
          { ...props }
        />
        <span className={styles['slider']}></span>
      </label>
      <label
        htmlFor={ id || inputId }
        { ...labelProps }
        onClick={ handleLabelClick }
      >
        { title }
      </label>
    </div>
  );
});

SwitchInput.displayName = 'SwitchInput';

export default React.memo(SwitchInput);
