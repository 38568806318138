import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Section as SectionConstants } from '@src/constants/sections';
import { IAccountsPayableRelatedJournal } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import { TOption } from '@src/components/ui_v2/inputs';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import Section from '@src/components/utils_v2/section';

import Accrued from './accrued';
import AdvancedSettlement from './advance_settlement';
import Intercompany from './intercompany';

interface IJournalTabsProps {
  document: IDocument;
  relatedJournal: IAccountsPayableRelatedJournal,
}

const JournalTabs = ({ document, relatedJournal }: IJournalTabsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TOption | null>(null);

  const onTabChange = useCallback((e: React.MouseEvent<HTMLAnchorElement>, tab: TOption) => {
    e.preventDefault();
    setActiveTab({ ...tab });
  }, []);

  const tabs: TOption[] = useMemo(() => {
    const options: TOption[] = [];
    const intercompanyEnabled = window.configData
      .launch_darkly_enabled_global_features
      .includes(window.Docyt.Common.Constants.INTERCOMPANY_AUTOMATION_FLAG);

    if (intercompanyEnabled && relatedJournal.intercompanyAdjustmentEntries.length > 0) {
      options.push({ label: 'Intercompany', value: 'intercompany' });
    }
    if (relatedJournal.accrualAdjustmentEntries.length > 0) {
      options.push({ label: 'Accrued', value: 'accrued' });
    }
    if (relatedJournal.adjustmentEntry) {
      if (document.transactionServiceDocumentId) {
        options.push({ label: 'Adjustment Entry', value: 'adjustment_entry' });
      } else {
        options.push({ label: 'Advanced Settlement', value: 'advance_settlement' });
      }
    }
    return options;
  }, [relatedJournal, document]);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab((prev) => prev || tabs[0]);
    }
  }, [tabs]);

  return (
    <>
      <div className="related-journal-entries-tabs">
        <Section.Provider section={ { section: SectionConstants.AccountsPayableService } }>
          <HeaderRegion>
            <HeaderRegion.Tabs>
              { tabs.map((tab: TOption) => (
                <HeaderRegion.Tab
                  key={ tab.value }
                  active={ activeTab?.value === tab.value }
                  href={ window.location.pathname + window.location.search }
                  title={ tab.label }
                  onTabSelected={ (e) => onTabChange(e, tab) }
                />
              )) }
            </HeaderRegion.Tabs>
          </HeaderRegion>
        </Section.Provider>
      </div>
      {activeTab ? (
        <div className="related-journal-entries-tabs-content">
          {activeTab.value === 'intercompany' && <Intercompany adjustmentEntries={ relatedJournal.intercompanyAdjustmentEntries } />}
          {activeTab.value === 'accrued' && <Accrued adjustmentEntries={ relatedJournal.accrualAdjustmentEntries } />}
          {activeTab.value === 'advance_settlement' && relatedJournal.adjustmentEntry && <AdvancedSettlement adjustmentEntry={ relatedJournal.adjustmentEntry } />}
          {activeTab.value === 'adjustment_entry' && relatedJournal.adjustmentEntry && <Accrued adjustmentEntries={ [relatedJournal.adjustmentEntry] } />}
        </div>
      ) : null}
    </>
  );
};

export default React.memo(JournalTabs);
