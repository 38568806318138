import React from 'react';

import { useParams } from 'react-router-dom';

import { invoiceQueueTabsPath } from '@src/routes';
import { currencyLocaleFormatter } from '@src/utils/currency';

import Alert from '@src/components/ui_v2/alerts/alert';
import { Button } from '@src/components/ui_v2/buttons';
import BannerRegion from '@src/components/ui_v2/layout/banner_region';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import {
  AchIcon,
  BulkSelfPrintCheckIcon,
  BusinessIcon,
  CashIcon,
  CheckCardIcon,
  CreditCardIcon,
  EditAccountIcon,
  EditIcon,
  ForwardOIcon,
  MergeInvoicesIcon,
  PaidIcon,
  PaidPrimaryIcon,
  PayPrimaryIcon,
  PlusIcon,
  StopIcon,
  TrashcanIcon,
  WarningIcon,
} from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import BannerInfo from './banner/banner_info';
import { useInvoiceQueueCollection } from './hooks';
import InvoiceQueueTable from './table';

import styles from './styles.module.scss';

const TABS = [
  {
    id: 1,
    title: 'All Invoices',
    type: 'queued',
  },
  {
    id: 2,
    title: 'Paid',
    type: 'paid',
  },
  {
    id: 3,
    title: 'Stopped',
    type: 'stopped',
  }
]

const InvoiceQueue = () => {
  const { businessId, queueType } = useParams();
  const collection = useInvoiceQueueCollection({
    businessId: Number(businessId),
    type: queueType as string,
  });

  return (
    <Section.Provider section={ collection.section }>
      <DetailsRegion>
        <HeaderRegion>
          <HeaderRegion.Tabs>
            { TABS.map((tab: { id: number, title: string, type: string }) => (
              <HeaderRegion.Tab
                key={ tab.id }
                active={ queueType === tab.type }
                href={ invoiceQueueTabsPath(Number(businessId), tab.type) }
                title={ tab.title }
              />
            )) }
          </HeaderRegion.Tabs>
          <HeaderRegion.BulkActions>
            <HeaderRegion.SelectedItems />
            <HeaderRegion.BulkActionsDropdown
              icon={ <PayPrimaryIcon className="font-22" /> }
              title="Pay"
            >
              <HeaderRegion.BulkAction
                icon={ <AchIcon className="font-22" /> }
                title="Pay with Docyt ACH"
              />
              <HeaderRegion.BulkAction
                icon={ <CheckCardIcon className="font-22" /> }
                title="Pay with Docyt Check"
              />
              <HeaderRegion.BulkAction
                icon={ <BulkSelfPrintCheckIcon className="font-28" /> }
                title="Pay with Self-print Check"
              />
            </HeaderRegion.BulkActionsDropdown>
            <HeaderRegion.BulkActionsDropdown
              icon={ <PaidPrimaryIcon className="font-22" /> }
              title="Mark as Paid"
            >
              <HeaderRegion.BulkAction
                icon={ <CashIcon className="font-22" /> }
                title="Cash"
              />
              <HeaderRegion.BulkAction
                icon={ <CreditCardIcon className="font-22" variant="simple" /> }
                title="Credit Card"
              />
              <HeaderRegion.BulkAction
                icon={ <BusinessIcon className="font-22" /> }
                title="Bank Account (ACH / Debit Card)"
              />
              <HeaderRegion.BulkAction
                icon={ <PaidIcon className="font-22" /> }
                title="Advanced Settlement"
              />
            </HeaderRegion.BulkActionsDropdown>
            <HeaderRegion.BulkActionsDropdown title="More">
              <HeaderRegion.BulkAction
                icon={ <MergeInvoicesIcon className="font-22" /> }
                title="Merge Invoices"
              />
              <HeaderRegion.BulkAction
                icon={ <ForwardOIcon className="font-22" /> }
                title="Forward"
              />
              <HeaderRegion.BulkAction
                icon={ <StopIcon className="font-22" /> }
                title="Do Not Pay"
              />
              <HeaderRegion.BulkAction
                icon={ <EditAccountIcon className="font-22" /> }
                title="Edit Invoices"
              />
              <HeaderRegion.BulkAction
                icon={ <EditIcon className="font-22" /> }
                title="Edit Category"
              />
              <HeaderRegion.BulkAction
                danger
                icon={ <TrashcanIcon className="font-22" /> }
                title="Delete Invoice"
              />
            </HeaderRegion.BulkActionsDropdown>
          </HeaderRegion.BulkActions>
          <HeaderRegion.ActionsArea>
            <Button
              prefixIcon={ <PlusIcon fontSize={ 14 } /> }
              variant="primary"
            >
              Add invoice
            </Button>
          </HeaderRegion.ActionsArea>
        </HeaderRegion>
        <BannerRegion>
          <Alert
            className="m-b-5"
            icon={ <WarningIcon fontSize={ 18 } mr={ 8 } /> }
            variant="primary"
          >
            This is a banner message
          </Alert>
        </BannerRegion>
        <TableSection>
          <InvoiceQueueTable collection={ collection } />
        </TableSection>
        <DetailsRegion.Footer className={ styles['footer-container'] }>
          <DetailsRegion.FooterHint>
            Drag one or more invoices to add in the queue
          </DetailsRegion.FooterHint>
          <DetailsRegion.FooterMetrics
            title="Total Invoices"
            value="210"
          />
          <DetailsRegion.FooterMetrics
            hint={ (
              <>
                Total Amount reflects the sum of all
                <br />
                invoices in the queue, across all states  (In
                <br />
                Verification, Needs Approval, Ready to
                <br />
                Pay, Ready for Credit, etc.). It dynamically
                <br />
                updates to reflect any filters applied or 
                <br />
                selected invoices within the queue.
              </>
            ) }
            title="Total Amount"
            value={ currencyLocaleFormatter(collection.totalAmount) }
          />
        </DetailsRegion.Footer>
      </DetailsRegion>
    </Section.Provider>
  );
};

export default React.memo(InvoiceQueue);
