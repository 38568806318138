import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import { TAccountType, TID } from '@src/types/common';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import AccountOwnerField from '@src/components/common/form_fields/account_owner_field';
import ClickTooltip from '@src/components/common_v2/click_tooltip/click_tooltip';
import Form from '@src/components/ui/form';

import ChartOfAccountsField from './chart_of_accounts/chart_of_accounts_field';
import BankAccountsField from './financial_institution_bank_accounts/bank_accounts_field';
import { accountSettingsValidation, IAccountSettingsInput } from './schema';

import styles from '../styles.module.scss';

interface IAccountSettingsFormProps {
  account?: IReconciliationPaymentAccount,
  paymentAccount?: IPaymentAccount,
  businessId: TID,
  mappingBankAccountsReconciliationCenterFeatureflag?: boolean | string | undefined,
  formId: string,
  onSubmit: (data: IAccountSettingsInput) => void,
}

const TransactionImportTypeOptions = [
  { value: window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.AUTO, name: 'Automatic' },
  { value: window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.MANUAL, name: 'Manual' },
];

const AccountSettingsForm = ({
  account,
  businessId,
  formId,
  mappingBankAccountsReconciliationCenterFeatureflag,
  paymentAccount,
  onSubmit,
}: IAccountSettingsFormProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setFocus,
  } = useForm<IAccountSettingsInput>({
    defaultValues: {
      name: account ? account.name : paymentAccount?.name,
      ...(mappingBankAccountsReconciliationCenterFeatureflag ? {} : {
        qbo: {
          id:   account ? account.qboId : paymentAccount?.qboId,
          name: account ? account.qboName : paymentAccount?.qboName,
        },
      }),
      last4:                 account ? account.last4 : paymentAccount?.last4,
      startImportDate:       account ? account.startImportDate : paymentAccount?.startImportDate,
      startBalance:          account ? account.startBalance : paymentAccount?.startBalance,
      transactionImportType: account ? account.transactionImportType : undefined,
      accountOwner:          paymentAccount ? paymentAccount.accountOwner : undefined,
      bankAccount:           account ? {
        financialInstitutionName: account.financialInstitutionBankAccount?.financialInstitutionName,
        financialInstitutionLogo: account.financialInstitutionBankAccount?.financialInstitutionLogo,
        bankAccountId:            account.financialInstitutionBankAccount?.id,
        bankAccountName:          account.financialInstitutionBankAccount?.name,
        bankAccountLast4:         account.financialInstitutionBankAccount?.last4,
      } : {},
    },
    mode:     'onBlur',
    resolver: yupResolver(accountSettingsValidation(mappingBankAccountsReconciliationCenterFeatureflag ?? false)),
  });

  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);
  const [startTyping, setStartTyping] = useState<boolean>(false);

  const qboController = useController({ name: 'qbo', control });
  const startImportDateController = useController({ name: 'startImportDate', control });
  const startBalanceController = useController({ name: 'startBalance', control });
  const bankAccountController = useController({ name: 'bankAccount', control });
  const accountOwnerController = useController({ name: 'accountOwner', control });

  const transactionImportType = watch('transactionImportType');

  const isCreditCard = account?.accountType === window.configData.payment_account_types.CREDIT_CARD;

  const handleClearStartingBalance = useCallback((agree: boolean) => {
    setFocus('startBalance');
    if (!agree) {
      setValue('startBalance', '');
    }
    setShowTooltip(false);
  }, [setValue, setFocus]);

  const onPressStartingBalance = useCallback(() => {
    if (!startTyping) setStartTyping(true);
  }, [startTyping]);

  useEffect(() => {
    if (startTyping && showTooltip === undefined
      && isCreditCard && Number(startBalanceController.field.value) < 0) {
      setShowTooltip(true);
    }
  }, [isCreditCard, showTooltip, startBalanceController.field.value, startTyping]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Account Name"
        placeholder="Bank Name - XXXX"
        { ...register('name') }
      />
      {!mappingBankAccountsReconciliationCenterFeatureflag && (
        <ChartOfAccountsField
          accountType={ account ? account.accountType as TAccountType
            : paymentAccount?.accountType as TAccountType }
          error={ errors.qbo?.id?.message }
          label="Chart of Accounts"
          paymentAccountId={ account ? account.defaultPaymentAccountId : paymentAccount?.id }
          value={ qboController.field.value }
          onChange={ qboController.field.onChange }
        />
      )}
      <Form.TextField
        className="account-number-input"
        error={ errors.last4?.message }
        label={ isCreditCard ? 'Credit Card No.' : 'Account No.' }
        mark="(last 4 digits)"
        maxLength={ 4 }
        { ...register('last4') }
      />
      <Form.DateField
        className="starting-date-input"
        error={ errors.startImportDate?.message }
        label="Starting Date"
        placeholder="Starting Date"
        { ...startImportDateController.field }
      />
      <ClickTooltip
        content={ (
          <Form.AmountField
            className="starting-balance-input"
            label="Starting Balance"
            placeholder="Starting Balance (Optional)"
            onKeyDown={ onPressStartingBalance }
            { ...startBalanceController.field }
          />
        ) }
        showTooltip={ showTooltip }
        title={
          window.Docyt.Common.Constants.Messages.BANKING_CREDIT_START_BALANCE_CONFIRM_MSG
        }
        onClick={ handleClearStartingBalance }
      />
      {
        isCreditCard && (
          <span className={ styles['credit-card-hint'] }>
            { window.Docyt.Common.Constants.Messages.BANKING_CREDIT_CARD_HINT }
          </span>
        )
      }
      {
        account ? (
          <Form.SelectField
            label="Transaction Import"
            options={ TransactionImportTypeOptions }
            { ...register('transactionImportType') }
          />
        ) : (
          <AccountOwnerField
            rcPaymentAccountId={ paymentAccount?.reconciliationPaymentAccountId }
            { ...accountOwnerController.field }
          />
        )
      }
      {
        account
        && transactionImportType === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.AUTO
        && (
          <div className="document-field">
            <BankAccountsField
              businessId={ businessId }
              error={ errors.bankAccount?.bankAccountId?.message }
              // isEditable={ !account.financialInstitutionBankAccount?.isActive }
              // Hide the edit button for inactive financial institution bank accounts
              isEditable={ false }
              value={ bankAccountController.field.value }
              onChange={ bankAccountController.field.onChange }
            />
          </div>
        )
      }
    </Form>
  );
};

export default AccountSettingsForm;
