import React, { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDBusinessFeatureQuery, useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  revenueServicePath,
  revenueServiceSettingsPath,
  revenueServicePathUnassignedPath,
  revenueServicePathAddSystemPath,
  revenueServicePathRevenueCapturePath,
} from '@src/routes';

import ServiceMenu from '@src/components/common/service_menu';
import { sortRevenueSystems } from '@src/components/revenue_center/utils';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import NewServiceMenu from '@src/components/ui_v2/service_sidebar';
import {
  AboutDocytIcon,
  SettingsGreenIcon,
  EnvelopeCustomIcon, AttachIcon,
} from '@src/components/utils/icomoon';
import AddCircleIcon from '@src/components/utils/icomoon/add_circle';

import RevenueSystemItem from './revenue_system_item';

const RevenueCenterSidemenu = () => {
  const business = useBusinessContext();
  const service = useRevenueServiceContext();

  const { data: globalNavigationBusinessEnabled } = useLDBusinessFeatureQuery(business.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);
  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);
  const { data: revenueCenterLeftNavigation } = useLDBusinessFeatureQuery(business.id, window.Docyt.Common.Constants.REVENUE_CENTER_LEFT_NAVIGATION);
  const { data: info } = useGetBusinessServicesInfo(business.id);

  const isGlobalNavigationEnabled: boolean = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));
  const isGlobalnavigationNewUi: boolean = isGlobalNavigationEnabled && !info?.isAccountingFirmUser;

  const revenueReportTypeMap = useMemo(() => {
    return new Map(service.revenueReportTypes.map((rt) => [rt.id, rt]));
  }, [service.revenueReportTypes]);

  const sortedRevenueSystems = useMemo(() => {
    return sortRevenueSystems(service.revenueSystems);
  }, [service.revenueSystems]);

  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_MAPPING_TEMPLATE,
  );

  if (revenueCenterLeftNavigation) {
    return (
      <NewServiceMenu
        serviceMenu={ (
          <ServiceMenu
            featureQuery={ featureQuery.data }
            service={ service }
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE }
          />
      ) }
      >
        {
          sortedRevenueSystems.map((system) => (
            <RevenueSystemItem
              key={ system.id }
              newNavigationRevenue={ revenueCenterLeftNavigation }
              revenueReportType={ revenueReportTypeMap.get(system.revenueReportTypeId) }
              revenueSystem={ system }
            />
          ))
        }
        <NewServiceMenu.Separator />
        <NewServiceMenu.Item
          href={ revenueServicePathUnassignedPath(business.id) }
          title="For Review"
        />
        <NewServiceMenu.Item
          href={ revenueServicePathAddSystemPath(business.id) }
          prefixIcon={ <AddCircleIcon fontSize={ 18 } /> }
          title="Add New Revenue System"
        />
        <NewServiceMenu.Separator />
        <NewServiceMenu.Item
          href={ revenueServiceSettingsPath(business.id) }
          title="Settings"
        />
      </NewServiceMenu>
    );
  }

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          featureQuery={ featureQuery.data }
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE }
        />
      ) }
    >
      { sortedRevenueSystems.length === 0 ? (
        <ServiceSidebar.Item
          href={ revenueServicePath(business.id) }
          icon={ <AboutDocytIcon /> }
          title="Get Started"
        />
      ) : (
        <ServiceSidebar.Item
          href={ revenueServicePathAddSystemPath(business.id) }
          icon={ <AttachIcon /> }
          title="Add Revenue System"
        />
      ) }
      {
        sortedRevenueSystems.map((system) => (
          <RevenueSystemItem
            key={ system.id }
            revenueReportType={ revenueReportTypeMap.get(system.revenueReportTypeId) }
            revenueSystem={ system }
          />
        ))
      }
      <ServiceSidebar.Item
        badge={ service.unassignedCount }
        href={ revenueServicePathUnassignedPath(business.id) }
        icon={ <EnvelopeCustomIcon variant="brown" /> }
        title="For review"
      />
      <ServiceSidebar.Item
        href={ revenueServiceSettingsPath(business.id) }
        icon={ <SettingsGreenIcon /> }
        title="Settings"
      />
    </ServiceSidebar>
  );
};

export default RevenueCenterSidemenu;
