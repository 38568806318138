import React from 'react';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import Section from '@src/components/utils_v2/section';

import { useFinancialInsightsEmailSettingsListCollection } from './hooks';
import Table from './table';

import styles from '../styles.module.scss';

const FinancialInsightsEmailSettingsList = () => {
  const collection = useFinancialInsightsEmailSettingsListCollection();

  return (
    <DetailsRegion>
      <div className={ styles['settings-list-container'] }>
        <Section.Provider section={ collection.section }>
          <Table
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </Section.Provider>
      </div>
    </DetailsRegion>
  );
};

export default React.memo(FinancialInsightsEmailSettingsList);
