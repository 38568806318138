import React from 'react';

import {
  DashboardsUnavailableIcon,
} from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

const DashboardUnavailable = () => {
  return (
    <div className={ styles['dashboard-no-permission'] }>
      <div className={ styles['empty-wrapper'] }>
        <div className={ styles['empty-image'] }>
          <DashboardsUnavailableIcon />
        </div>
        <p className={ styles['empty-title'] }>
          Dashboard Data is Unavailable
        </p>
        <p className={ styles['empty-text'] }>
          There are no portfolio available.
          Please create a portfolio including businesses
          from the same industry or use the business filter
          to select businesses from
          the same industry to view the dashboard.
        </p>
      </div>
    </div>
  );
};

export default DashboardUnavailable;
