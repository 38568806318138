import React, { useCallback, useState } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import { CloseIcon } from '@src/components/utils/icomoon';

import { Button } from '../buttons';

import styles from './styles.module.scss';

interface BannerProps extends IUIStyleProps {
  children: React.ReactNode,
  icon?: React.ReactNode,
  variant: 'success' | 'info' | 'warning' | 'danger' | 'error' | 'highlight' | 'primary',
  onClose?: () => void;
  selfClose?: boolean;
}

const Alert = ({
  children,
  icon,
  variant,
  onClose,
  selfClose,
  ...props
}: BannerProps) => {
  const showClose = selfClose || onClose;
  const [isClosed, setIsClosed] = useState(false);
  const [classes, divProps] = uiStyleProps(
    styles.alert,
    props,
    'alert',
    `alert-${variant}`,
    {
      'alert-with-close': showClose,
    },
  );
  const handleClickClose = useCallback(() => {
    if (selfClose) setIsClosed(true);
    if (onClose) onClose();
  }, [setIsClosed, selfClose, onClose]);

  if (isClosed) {
    return null;
  }

  return (
    <div className={ classes } { ...divProps }>
      { icon && (
        <>
          { icon }
          { ' ' }
        </>
      ) }
      { children }
      { showClose && (
        <CloseIcon pointer fontSize={ 32 } inColor="grey-1050" ml="auto" onClick={ handleClickClose } />
      ) }
    </div>
  );
};

export default Alert;
