import React from 'react';

import MultiEntityReportsProvider from '@src/components/providers/multi_entity_reports_provider';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container';

import Router from './router';

const MultiEntityReportsPage = ({ index }: { index: string }) => {
  return (
    <Root>
      <MultiEntityReportsProvider>
        <PageContainer>
          <DetailsRegion>
            <Router />
          </DetailsRegion>
        </PageContainer>
      </MultiEntityReportsProvider>
    </Root>
  );
};

export default MultiEntityReportsPage;
