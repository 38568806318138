import React, { useState } from 'react';
import { SwitchInput } from '@src/components/ui_v2/inputs';

interface IRevenueCenterProps {
  initialEnabled: boolean;
  handleCheckboxChange: (checked: boolean) => void;
}

const RevenueCenter = ({ initialEnabled, handleCheckboxChange }: IRevenueCenterProps): JSX.Element | null => {
  const [revenueCenterEnabled, setRevenueCenterEnabled] = useState(initialEnabled);

  const onCheckboxChange = (checked: boolean, value: string | undefined) => {
    setRevenueCenterEnabled(checked);
    handleCheckboxChange(checked);
  };

  return (
    <div className="revenue-center-container">
      <div className="form-group">
        <label className="field-label">Revenue Center</label>
        <div className="field-input">
          <div className="field-input-container">
            <SwitchInput
              checked={ revenueCenterEnabled }
              onChange={ onCheckboxChange }
              title={ revenueCenterEnabled ? 'Enabled' : 'Disabled' }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueCenter;