import DetailsRegion from './details_region';
import Footer from './footer';
import FooterHint from './footer/footer_hint';
import FooterMetrics from './footer/footer_metrics';
import Header from './header';
import Provider from './provider';
import TwoLineHeader from './two_line_header';

export default Object.assign(DetailsRegion, {
  Header,
  TwoLineHeader,
  Footer,
  FooterHint,
  FooterMetrics,
  Provider,
});
