import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IClientBusiness, TClientBusinessesSortColumn } from '@src/types/clients';
import { ISorting } from '@src/types/sorting';

import AddBusinessClient from './main_details/add_business_client';
import BlankPage from './main_details/blank_page';
import Table from './main_details/list/table';

interface IBodyProps {
  clientBusinesses: IClientBusiness[],
  searchCount: number,
  totalCount: number,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TClientBusinessesSortColumn>,
}

const Body = ({
  clientBusinesses,
  searchCount,
  totalCount,
  query,
  sorting,
}: IBodyProps): JSX.Element => {
  let bodyContent;

  if (totalCount === 0) {
    bodyContent = (
      <BlankPage />
    );
  } else if (totalCount > 0 && searchCount > 0) {
    bodyContent = (
      <div className="banking-accounts-table-wrapper">
        <Table
          clientBusinesses={ clientBusinesses }
          query={ query }
          sorting={ sorting }
        />
      </div>
    );
  } else {
    bodyContent = (
      <AddBusinessClient />
    );
  }
  return (
    <div>
      { bodyContent }
    </div>
  );
};

export default Body;
